import useAxios from "./use-axios";
import { API } from "../../../package.json";

export function useAPI(endpoint, { manual, ...axiosConfig }) {
  const parseEndpoint = endpoint.match(/^([A-Z]*?) ((https:\/\/|\/).*?)$/);
  if (!parseEndpoint)
    throw new Error(`Impossible de parser l'endpoint fourni : ${endpoint}`);
  const [, method, url] = parseEndpoint;
  return useAxios(
    {
      baseURL: API,
      url,
      timeout: 20000,
      method,
      ...axiosConfig,
    },
    manual || false
  );
}

export function useAuthenticatedRequest(endpoint, config = {}) {
  const jwt = localStorage.getItem("jwt");
  const { status, result, ...state } = useAPI(endpoint, {
    ...(jwt ? { headers: { Authorization: `Bearer ${jwt}` } } : {}),
    ...config,
  });

  return { status, result, ...state };
}
