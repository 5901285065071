import React from "react";
import { Iconly } from "react-iconly";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import { Invited } from "../Planning/Invited";
import { Loader } from "../../components/Loader";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { isMobileOnly } from "react-device-detect";
import { Error } from "../../components/Error";

export const Rdv = () => {
  let location = useLocation();
  const { status, result, doRequest } = useAuthenticatedRequest(
    "GET /events/soon/rdv"
  );
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, doRequest]);

  return (
    <>
      {status === statuses.REJECTED && (
        <Error result={result} doRequest={doRequest} />
      )}

      {status === statuses.PENDING && <Loader />}
      <div style={{ display: "flex" }}>
        <h2>Mes prochains rendez-vous</h2>
        <div className="circle">
          <p>{result?.data?.length}</p>
        </div>
      </div>

      <Swiper spaceBetween={5} slidesPerView={isMobileOnly ? 1.15 : 5}>
        {result?.data?.map((item) => (
          <SwiperSlide key={item.id}>
            <Link
              to={{
                pathname: "/home/event/" + item.id,
                state: {
                  id: item.id,
                  title: item.title,
                  type: item.type,
                  createur: item.createur,
                  photo_createur: item.photo_createur,
                  date_creation: item.date_creation,
                  startDate: item.startDate,
                  endDate: item.endDate,
                },
              }}
            >
              <div
                className={
                  new Date(item.startDate) < new Date() &&
                  new Date(item.endDate) > new Date()
                    ? "animated"
                    : null
                }
                style={{
                  backgroundImage: `linear-gradient(
                      to right,
                      #fff0 20%,
                      #fff0 40%,
                      ${item.color} 10%,
                      ${item.color}  10%,
                      #fff0 70%,
                      #fff0 100%
                    )`,
                }}
              />
              <div className="card" style={{ backgroundColor: item.color }}>
                <div className="card-content">
                  <h1>{item.title}</h1>

                  <div className="description">
                    <Iconly
                      name="Calendar"
                      primaryColor="#fff"
                      set="light"
                      size="medium"
                    />
                    {new Date(item.startDate).toLocaleDateString() ===
                    new Date(item.endDate).toLocaleDateString()
                      ? `${new Date(item.startDate).toLocaleDateString()}`
                      : `${new Date(
                          item.startDate
                        ).toLocaleDateString()} au ${new Date(
                          item.endDate
                        ).toLocaleDateString()}`}{" "}
                    <br />
                    de{" "}
                    <b>
                      {new Date(item.startDate).getHours()}h
                      {new Date(item.startDate).getMinutes()}
                    </b>{" "}
                    à{" "}
                    <b>
                      {new Date(item.endDate).getHours()}h
                      {new Date(item.endDate).getMinutes()}
                    </b>
                  </div>
                  <div className="invited-content-card">
                    <div className="round-account">
                      <img src={item.photo_createur} alt="" />
                    </div>
                    <Invited id={item.id} />
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
        {result?.data?.length === 0 && (
          <p className="nothing">Aucun événement !</p>
        )}
      </Swiper>
    </>
  );
};
