import React from "react";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";

export const AddToCalendar = () => {
  const { result } = useAuthenticatedRequest("GET /users/me");

  return (
    <>
      <Link to="/planning/menu" className="background-modal" />
      <div className="modal sheet" style={{ height: 550 }}>
        <Link to="/planning/menu" className="closeModal">
          <p>&times;</p>
        </Link>
        <h1 className="title">Ajouter à votre calendrier</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 50,
            flexWrap: "wrap",
          }}
        >
          <QRCode
            value={`https://api-gestion-interne.fibeer.fr/calendar/${result?.data?.id}.ics`}
          />
          <p>Scannez depuis votre téléphone</p>
        </div>
      </div>
    </>
  );
};
