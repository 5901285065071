// @flow

import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { isMobile } from "react-device-detect";

const Ctx = React.createContext();

const Toast = () => (
  <Toaster position={isMobile ? "top-center" : "bottom-center"} />
);

let toastCount = 0;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const success = (content) => {
    const id = toastCount++;
    const toaste = { content, id };
    toast.success(content, {
      duration: 5000,
      style: {
        padding: "12px",
        paddingLeft: "22px",
        paddingRight: "22px",
        color: "#fff",
        background: "#93c93c",
      },
      iconTheme: {
        primary: "#fff",
        secondary: "#93c93c",
      },
    });
    setToasts([...toasts, toaste]);
  };
  const error = (content) => {
    const id = toastCount++;
    const toaste = { content, id };
    toast.error(content, {
      duration: 5000,
      style: {
        padding: "12px",
        paddingLeft: "22px",
        paddingRight: "22px",
        color: "#fff",
        background: "#ff004d",
      },
      iconTheme: {
        primary: "#fff",
        secondary: "#ff004d",
      },
    });
    setToasts([...toasts, toaste]);
  };

  return (
    <Ctx.Provider value={{ success, error }}>
      {children}
      <Toast />
    </Ctx.Provider>
  );
}

export const useToasts = () => React.useContext(Ctx);
