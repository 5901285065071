import React from "react";
import { usePersonne } from "..";
import { useAuthenticatedRequest } from "./store/use-api";
import { statuses } from "./store/use-axios";
import useLocalStorage from "./store/use-localstorage";

export const ListUser = ({
  setPhoto,
  setInvited,
  invited,
  me,
  setNom,
  setIdUser,
  idUser,
}) => {
  const [user] = useLocalStorage("user");
  const [personne, setPersonne] = usePersonne();

  const { status, result, doRequest } = useAuthenticatedRequest("GET /users");
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRequest]);

  const set = (item) => {
    if (setNom) {
      setNom(item.nom);
    }
    if (setPhoto && setInvited) {
      setPhoto(item.photo);
      setInvited(item.email);
    }
    if (setPersonne && me) {
      setPersonne(item.nom);
    }
    if (setIdUser) {
      setIdUser(item.id);
    }
  };

  return (
    <>
      {me && (
        <li
          className={personne === "all" ? "active" : null}
          onClick={() => setPersonne("all")}
        >
          <p>Tous</p>
        </li>
      )}
      {!!result?.data
        ? result?.data.map((item) => (
            <>
              {me && item.nom === user && (
                <li
                  className={
                    invited === item.email ||
                    personne === item.nom ||
                    idUser === item.id
                      ? "active"
                      : null
                  }
                  style={
                    invited === item.email ||
                    personne === item.nom ||
                    idUser === item.id
                      ? { backgroundColor: item.color }
                      : null
                  }
                  onClick={() => set(item)}
                  key={item.id}
                >
                  <div className="round-account">
                    <img src={item.photo} alt="" />
                  </div>
                  <p>
                    <b>Moi</b>
                  </p>
                </li>
              )}
              {item.nom === user ? null : (
                <li
                  className={
                    invited === item.email ||
                    personne === item.nom ||
                    idUser === item.id
                      ? "active"
                      : null
                  }
                  style={
                    invited === item.email ||
                    personne === item.nom ||
                    idUser === item.id
                      ? { backgroundColor: item.color }
                      : null
                  }
                  onClick={() => set(item)}
                  key={item.id}
                >
                  <div className="round-account">
                    <img src={item.photo} alt="" />
                  </div>
                  <p>{item.nom}</p>
                </li>
              )}
            </>
          ))
        : null}
    </>
  );
};
