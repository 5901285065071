import React from "react";

export const Logo = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="300.000000pt"
      height="100.000000pt"
      viewBox="0 0 1200.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill="transparent"
        strokeLinecap="round"
        fillRule="evenodd"
        stroke="transparent"
        strokeWidth="20"
      >
        <path
          id="C"
          d="M1655 2860 c-207 -41 -385 -127 -552 -268 l-53 -44 0 -129 0 -129
-104 0 -103 0 -32 -59 c-17 -32 -31 -62 -31 -65 0 -3 59 -6 130 -6 l130 0 0
-245 0 -245 -120 0 -120 0 0 -65 0 -65 -65 0 c-45 0 -65 -4 -65 -12 0 -29 41
-208 60 -268 31 -92 88 -204 155 -305 52 -79 222 -249 308 -308 136 -94 325
-170 498 -202 87 -15 330 -16 409 -1 125 24 271 75 370 129 51 28 168 104 180
117 3 3 30 26 61 52 102 86 295 347 272 368 -4 4 -122 51 -261 103 l-253 95
-16 -26 c-46 -76 -157 -173 -258 -226 -143 -77 -360 -91 -523 -35 -313 108
-506 449 -436 770 26 121 94 250 172 328 101 100 168 141 302 182 94 29 251
31 350 6 134 -35 270 -121 350 -220 40 -51 45 -54 69 -45 33 13 324 122 418
156 40 15 73 32 73 39 0 31 -97 169 -179 257 -146 154 -373 293 -558 342 -181
48 -410 57 -578 24z m-287 -387 l3 -103 -101 0 -100 0 0 99 c0 70 4 101 13
105 6 3 50 4 97 3 l85 -2 3 -102z m-158 -338 l0 -45 -45 0 -45 0 0 45 0 45 45
0 45 0 0 -45z m-70 -605 l0 -70 -64 0 -64 0 -4 66 c-1 36 -1 68 1 70 2 2 33 4
68 4 l63 0 0 -70z m0 -205 l0 -85 90 0 90 0 0 -170 0 -170 -175 0 -175 0 0 95
0 95 -75 0 -75 0 0 160 0 160 160 0 160 0 0 -85z m340 -445 l0 -50 -50 0 -50
0 0 50 0 50 50 0 50 0 0 -50z"
        />
        <path
          id="greenSquare"
          d="M830 1250 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z"
        />
        <path
          id="G"
          d="M5310 2824 c-326 -42 -574 -161 -768 -369 -124 -133 -208 -290 -259
-484 -34 -130 -44 -398 -19 -540 83 -474 420 -818 902 -921 128 -27 370 -37
525 -21 200 21 421 79 580 152 l69 32 0 588 0 589 -465 0 -465 0 0 -215 0
-215 215 0 215 0 0 -204 0 -204 -42 -16 c-165 -61 -427 -57 -587 8 -185 75
-320 238 -368 443 -24 105 -25 312 0 403 33 124 92 227 181 315 93 92 167 136
282 165 93 24 238 26 339 5 90 -19 216 -81 283 -141 45 -39 55 -45 66 -33 34
38 303 346 314 360 10 14 3 23 -48 63 -123 97 -309 177 -503 217 -99 20 -361
34 -447 23z"
        />
        <path
          id="R"
          d="M8020 1656 l0 -1116 260 0 260 0 0 895 0 895 198 0 c146 0 213 -4
257 -15 121 -32 184 -99 192 -208 15 -181 -97 -274 -354 -293 l-93 -7 0 -204
0 -205 231 -421 c127 -232 234 -425 238 -430 4 -4 147 -6 318 -5 l312 3 -290
473 c-268 439 -287 474 -266 480 12 3 62 26 112 50 162 82 271 217 311 388 23
98 15 304 -16 391 -58 167 -194 300 -376 369 -169 64 -200 67 -771 71 l-523 4
0 -1115z"
        />
        <path
          id="orangeSquare"
          d="M630 2515 l0 -205 200 0 200 0 0 205 0 205 -200 0 -200 0 0 -205z"
        />
        <path
          id="blueSquare"
          d="M50 2265 l0 -215 215 0 215 0 0 215 0 215 -215 0 -215 0 0 -215z"
        />
        <path
          id="É"
          d="M10860 2203 l-121 -168 148 -3 147 -3 171 171 170 170 -197 0 -197 0
-121 -167z"
        />
        <path
          id="point"
          d="M3240 1660 l0 -230 230 0 230 0 0 230 0 230 -230 0 -230 0 0 -230z"
        />
        <path
          id="point2"
          d="M6917 1883 c-4 -3 -7 -107 -7 -230 l0 -223 230 0 230 0 0 230 0 230
-223 0 c-123 0 -227 -3 -230 -7z"
        />
        <path
          id="E"
          d="M10430 1215 l0 -675 495 0 495 0 0 150 0 150 -300 0 -300 0 0 115 0
115 265 0 265 0 0 150 0 150 -265 0 -265 0 0 105 0 105 280 0 280 0 0 155 0
155 -475 0 -475 0 0 -675z"
        />
        <path
          id="pinkSquare"
          d="M550 1670 l0 -120 115 0 115 0 0 120 0 120 -115 0 -115 0 0 -120z"
        />
        <path
          id="blackSquare"
          d="M370 1395 l0 -105 105 0 105 0 0 105 0 105 -105 0 -105 0 0 -105z"
        />
      </g>
    </svg>
  );
};
