import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { AddEvent } from "./AddEvent";
import { Iconly } from "react-iconly";
import { EventCalendar } from "./EventCalendar";
import { Sidebar } from "./Sidebar";
import { usePersonne } from "../../index";
import useLocalStorage from "../../components/store/use-localstorage";

export const Calendar = () => {
  const [personne] = usePersonne();
  const [user] = useLocalStorage("user");
  return (
    <>
      <div className="toolbar">
        <Link className="btn-start" to="/planning/menu">
          <Iconly
            name="Category"
            primaryColor="#000"
            set="bold"
            style={{ width: 38, height: 38 }}
          />
        </Link>

        <Link
          className="btn-end"
          to={{
            pathname: "/planning/addevent",
            state: {
              titleCopy: "",
              startDateCopy: "",
              endDateCopy: "",
              typeCopy: "",
              publicCopy: false,
            },
          }}
        >
          <Iconly
            name="Plus"
            primaryColor="#30c4f5"
            set="bold"
            style={{ width: 38, height: 38 }}
          />
        </Link>
      </div>

      <div className="desktop-toolbar">
        <Link className="btn-end" to="/planning/menu">
          <Iconly
            name="Category"
            primaryColor="#000"
            set="bold"
            style={{ width: 38, height: 38 }}
          />
        </Link>
        <Link
          className="btn-end-bottom"
          to={{
            pathname: "/planning/addevent",
            state: {
              titleCopy: "",
              startDateCopy: "",
              endDateCopy: "",
              typeCopy: "",
              publicCopy: false,
            },
          }}
        >
          <p>Ajouter un événement</p>
          <span
            style={{
              fontSize: 24,
              fontWeight: 300,
              marginTop: 10,
              marginLeft: 5,
            }}
          >
            +
          </span>
        </Link>
      </div>
      <div style={{ display: "flex" }}>
        <h1 className="title">
          {personne === user ? "Mon Planning" : "Planning"}
        </h1>
        {personne === user ? null : (
          <p style={{ textAlign: "center", marginTop: 58 }}>
            de <b>{personne === "all" ? "l'équipe C.G.Ré" : personne}</b>
          </p>
        )}
      </div>
      <EventCalendar />
      <Switch>
        <Route path="/planning/menu" component={Sidebar} />
        <Route path="/planning/addevent" component={AddEvent} />
      </Switch>
    </>
  );
};
