import React from "react";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import Select from "react-select";
import SignatureCanvas from "react-signature-canvas";
import { useToasts } from "../../components/store/toast-manager";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
registerLocale("fr", fr);

export const AddAbsence = () => {
  let history = useHistory();
  const { error, success } = useToasts();
  const [type, setType] = React.useState("");
  const [startDate, setStartDate] = React.useState();
  const [retourDate, setRetourDate] = React.useState();
  const [autre, setAutre] = React.useState("");
  const [dataURL, setDataURL] = React.useState();
  const [noKeyboard, setNoKeyboard] = React.useState(false);

  const isWeekday = (date) => {
    const day = date.getDay(date);
    return day !== 0 && day !== 6;
  };

  const { status, result, doRequest } = useAuthenticatedRequest(
    "POST /absences/create",
    {
      manual: true,
      data: {
        type: autre ? type.value + " : " + autre : type.value,
        startDate,
        retourDate,
        signature: dataURL,
      },
    }
  );
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (noKeyboard) {
      setTimeout(() => {
        document.activeElement.blur();
        setNoKeyboard(false);
      }, 10);
    }
    if (resolved) {
      success(result?.data?.message);
      history.push("/absence");
    }
    if (dataURL) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noKeyboard, resolved, dataURL]);

  const options = [
    { value: "Congés payés", label: "Congés payés" },
    { value: "Autres", label: "Autres" },
  ];

  let sigPad = React.useRef({});

  function clear() {
    sigPad.current.clear();
  }

  function trim() {
    if (sigPad.current.isEmpty()) {
      error("Il manque une signature !");
    } else {
      setDataURL(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
    }
  }

  return (
    <>
      <Link to="/absence" className="background-modal" />
      <div className="modal addevent">
        <Link to="/absence" className="closeModal">
          <p>&times;</p>
        </Link>
        <h1 className="title">Faire une demande</h1>

        <div className="container-modal">
          <p>
            Veuillez remplir le formulaire ci-dessous pour faire une demande
            d’absence.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              trim();
            }}
          >
            <Select
              isSearchable={false}
              noOptionsMessage={() => "Ce type n'exsite pas !"}
              classNamePrefix="react-select"
              className="select conge"
              defaultValue={type}
              onChange={setType}
              options={options}
              placeholder="Type"
            />

            {type.value === "Autres" && (
              <>
                <input
                  placeholder="Motif"
                  className="input"
                  style={{ width: "100%" }}
                  onChange={(e) => setAutre(e.target.value)}
                  value={autre}
                />
              </>
            )}
            <div onClick={() => setNoKeyboard(true)}>
              <DatePicker
                placeholderText="Date de départ"
                showPopperArrow={false}
                minDate={new Date()}
                startDate={startDate}
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                locale="fr"
                className="input input-datePicker"
                withPortal
                filterDate={isWeekday}
                required
              />

              <DatePicker
                placeholderText="Date de retour"
                showPopperArrow={false}
                selected={retourDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setRetourDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={retourDate}
                minDate={startDate}
                locale="fr"
                disabled={!startDate}
                className="input input-datePicker"
                withPortal
                filterDate={isWeekday}
                required
              />
            </div>
            <div className="signature">
              <p>Signature du demandeur : </p>
              <button type="button" onClick={clear}>
                Effacer
              </button>
              <SignatureCanvas ref={sigPad} penColor="black" />
            </div>
            <button type="submit" className="btn">
              Envoyer la demande
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
