import React from "react";
import { Iconly } from "react-iconly";
import { Link, Switch, Route, useLocation } from "react-router-dom";
import { AdminRoute } from "../..";
import { Error } from "../../components/Error";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { AbsenceItem } from "./AbsenceItem";

export const AbsenceAdmin = () => {
  let location = useLocation();
  const { status, result, doRequest } =
    useAuthenticatedRequest("GET /absences/all");
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <h1 className="title">Gérer les absences</h1>
      <div
        className="container"
        style={{ textAlign: "left", paddingBottom: 170 }}
      >
        <div style={{ marginBottom: 50 }}>
          <div style={{ display: "flex" }}>
            <h2>En attente</h2>
            <div className="circle">
              <p>
                {
                  result?.data?.filter((item) =>
                    item.status.toLowerCase().includes("pending")
                  ).length
                }
              </p>
            </div>
          </div>
          {result?.data
            ?.filter((item) => item.status.toLowerCase().includes("pending"))
            .map((item) => (
              <Link
                key={item.id}
                to={{ pathname: "/absence-admin/" + item.id }}
                className="item-absence"
                style={{ borderLeft: `20px solid #FF8E25` }}
              >
                <label>
                  {item.type} de {item.nomDemandeur}
                </label>
                <p>
                  {new Date(item.startDate).toLocaleDateString()} au{" "}
                  {new Date(item.retourDate).toLocaleDateString()}
                </p>
              </Link>
            ))}
          {result?.data?.filter((item) =>
            item.status.toLowerCase().includes("pending")
          ).length === 0 && <p>Vide</p>}
        </div>

        <div style={{ marginBottom: 50 }}>
          <div style={{ display: "flex" }}>
            <h2>Accepté</h2>
            <div className="circle">
              <p>
                {
                  result?.data?.filter((item) =>
                    item.status.toLowerCase().includes("accept")
                  ).length
                }
              </p>
            </div>
          </div>
          {result?.data
            ?.filter((item) => item.status.toLowerCase().includes("accept"))
            .map((item) => (
              <Link
                key={item.id}
                to={{ pathname: "/absence-admin/" + item.id }}
                className="item-absence accept"
                style={{ borderLeft: `20px solid #93c93c` }}
              >
                <label>
                  {item.type} de {item.nomDemandeur}
                </label>
                <p>
                  {new Date(item.startDate).toLocaleDateString()} au{" "}
                  {new Date(item.retourDate).toLocaleDateString()}
                </p>
              </Link>
            ))}
          {result?.data?.filter((item) =>
            item.status.toLowerCase().includes("accept")
          ).length === 0 && <p>Vide</p>}
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <h2>Refusé</h2>
            <div className="circle">
              <p>
                {
                  result?.data?.filter((item) =>
                    item.status.toLowerCase().includes("refuse")
                  ).length
                }
              </p>
            </div>
          </div>
          {result?.data
            ?.filter((item) => item.status.toLowerCase().includes("refuse"))
            .map((item) => (
              <Link
                key={item.id}
                to={{ pathname: "/absence-admin/" + item.id }}
                className="item-absence refuse"
                style={{ borderLeft: `20px solid #ff004d` }}
              >
                <label>
                  {item.type} de {item.nomDemandeur}
                </label>
                <p>
                  {new Date(item.startDate).toLocaleDateString()} au{" "}
                  {new Date(item.retourDate).toLocaleDateString()}
                </p>
              </Link>
            ))}
          {result?.data?.filter((item) =>
            item.status.toLowerCase().includes("refuse")
          ).length === 0 && <p>Vide</p>}
        </div>
      </div>

      <Switch>
        <AdminRoute path="/absence-admin/:id" component={AbsenceItem} />
      </Switch>
    </>
  );
};
