import React from "react";
import CSVReader from "react-csv-reader";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { ButtonImport } from "./ButtonImport";
import { v4 as uuidv4 } from 'uuid';
import useLocalStorage from "../../components/store/use-localstorage";
import excel from "../../img/excel.png";
import { Help } from "./Help";
registerLocale("fr", fr);

export const ImportTask = ({ history }) => {
  const [modal, setModal] = React.useState(false);
  const [user] = useLocalStorage("user");
  const [data, setData] = React.useState();
  const [fileInfo, setFileInfo] = React.useState();

  const closeModal = () => {
    setModal(false);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: false,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  function escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\\[\]\\/\\])/g, "\\$1");
  }

  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  }

  const replaceId =
    data &&
    replaceAll(JSON.stringify(data), '"id":""', () => {
      return `"id":"${uuidv4()}"`;
    });

  const replaceCreateur =
    data &&
    replaceAll(replaceId, '"createur":""', () => {
      return `"createur":"${user}"`;
    });

  const replaceDateCreation =
    data &&
    replaceAll(replaceCreateur, '"date_creation":""', () => {
      return `"date_creation":"${new Date()}"`;
    });

  const resultData = data && JSON.parse(replaceDateCreation);

  return (
    <>
      <div className="background-modal" />
      <div className="modal-addTask" style={{ width: "60%", height: "70%" }}>
        <Link to="/missions" className="closeModal">
          <p>&times;</p>
        </Link>
        <div className="headerTask" style={{ backgroundColor: "#30c5f5" }}>
          <h5 style={{ textAlign: "left", margin: 15, marginLeft: 20 }}>
            Importer des tâches
          </h5>
          <button
            className="btn"
            style={{ marginTop: 14, marginRight: 100, width: 220 }}
            onClick={() => setModal(true)}
          >
            Comment ça marche ?
          </button>
          <a className="icon-download" href="https://api-gestion-interne.fibeer.fr/ressources/Suivi%20des%20missions%20-%20Fichier%20d'import.csv">
            <img src={excel} alt="" />
          </a>
        </div>
        <div style={{ marginTop: 85 }}>
          <label className="dropzone">
            <CSVReader
              onFileLoaded={(data, fileInfo) =>
                setData(data) + setFileInfo(fileInfo)
              }
              parserOptions={papaparseOptions}
            />
            {!fileInfo ? (
              <p style={{ textTransform: "initial", margin: "auto" }}>
                Cliquez ici pour sélectionner votre fichier.
              </p>
            ) : (
              <p style={{ textTransform: "initial", margin: "auto" }}>
                Vous avez sélectionné : <b>{fileInfo?.name}</b>
              </p>
            )}
          </label>
          <div
            style={{
              position: "relative",
              width: "50%",
            }}
          >
            <h3>Résumé des tâches : </h3>
            {resultData && (
              <p style={{ marginLeft: 12 }}>
                Nombre de tâches : {resultData?.length}
              </p>
            )}
            <div style={{ overflow: "auto", height: 420 }}>
              {resultData ? (
                resultData.map((item) => (
                  <div key={item.id} className={"card-item " + item.statut}>
                    <div style={{ display: "flex", marginTop: "-10px" }}>
                      <div
                        style={
                          item.statut === ""
                            ? { width: "25%" }
                            : { width: "33%" }
                        }
                      >
                        <p className={item.type === "" && "danger"}>
                          {item.type === "" ? "Erreur type" : item.type}
                        </p>
                      </div>
                      <div
                        style={
                          item.statut === ""
                            ? { width: "25%" }
                            : { width: "33%" }
                        }
                      >
                        <p className={item.date_limite === "" && "danger"}>
                          {item.date_limite === ""
                            ? "Erreur date"
                            : item.date_limite}
                        </p>
                      </div>
                      <div
                        style={
                          item.statut === ""
                            ? { width: "25%" }
                            : { width: "33%" }
                        }
                      >
                        <p className={item.dossier === "" && "danger"}>
                          {item.dossier === ""
                            ? "Erreur dossier"
                            : item.dossier}
                        </p>
                      </div>
                      <div
                        style={
                          item.statut === "" ? { width: "25%" } : { width: 0 }
                        }
                      >
                        <p className={item.statut === "" && "danger"}>
                          {item.statut === "" ? "Erreur statut" : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ marginLeft: 12 }}>Aucun fichier importé</p>
              )}
            </div>
          </div>
        </div>
        <ButtonImport data={resultData} history={history} />
      </div>
      {modal && (
        <>
          <Help closeModal={closeModal} />
        </>
      )}
    </>
  );
};
