import Axios from "axios";
import React from "react";
import { useLocation, useParams } from "react-router";
import { API } from "../../../package.json";
import { Logo } from "../../components/Logo/Logo";
import { fetcher } from "../../components/reducer/fetcher";
import Lottie from "react-lottie";
import * as check from "./check.json";
import * as cross from "./cross.json";

export const Invitation = () => {
  let location = useLocation();
  let params = useParams();
  const [state, dispatch] = React.useReducer(fetcher, {
    loading: false,
    error: null,
    data: {},
  });

  const changeStatus = async () => {
    try {
      dispatch({ type: "LOADING" });
      const { data } = await Axios.put(
        API + `events/invitation/${params.id}/${params.status}`
      );
      dispatch({ type: "SUCCESS", payload: data });
    } catch (e) {
      dispatch({ type: "ERROR", payload: e });
    }
  };

  React.useEffect(() => {
    changeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { data } = state;

  console.log(data);

  const optionCheck = {
    loop: false,
    autoplay: true,
    animationData: check.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const optionCross = {
    loop: false,
    autoplay: true,
    animationData: cross.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {params.status === "accept" ? (
        <div className="container">
          <Logo />
          <h2>Vous avez accepté l'invitation !</h2>
          <Lottie options={optionCheck} height={100} width={100} />
        </div>
      ) : (
        <div className="container">
          <Logo />
          <h2>Vous avez refusé l'invitation !</h2>
          <Lottie options={optionCross} height={100} width={100} />
        </div>
      )}
    </>
  );
};
