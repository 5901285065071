import React from "react";
import "react-color-palette/lib/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useHistory } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { useToasts } from "../../components/store/toast-manager";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";

export const CreateUser = () => {
  let history = useHistory();
  const [nom, setNom] = React.useState("");
  const [email, setEmail] = React.useState("");

  const { success } = useToasts();

  const { status, result, doRequest } = useAuthenticatedRequest(
    "POST /users/create",
    {
      manual: true,
      data: {
        nom,
        email,
      },
    }
  );

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      success(result?.data?.message);
      history.push("/profil");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolved]);

  return (
    <>
      <Link to="/profil/manager-user" className="background-modal" />
      {status === statuses.PENDING && <Loader />}
      <div className="modal sheet" style={{ height: 500 }}>
        <Link to="/profil/manager-user" className="closeModal">
          <p>&times;</p>
        </Link>
        <h1 className="title">Créer un utilisateur</h1>
        <div className="container-modal">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              doRequest();
            }}
          >
            <input
              required
              placeholder="Prénom"
              style={{ width: "100%" }}
              className="input"
              type="text"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
            />
            <input
              required
              placeholder="Email"
              style={{ width: "100%" }}
              className="input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">Créer</button>
          </form>
        </div>
      </div>
    </>
  );
};
