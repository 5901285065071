import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Loader } from "../../components/Loader";
import { useToasts } from "../../components/store/toast-manager";
import SignatureCanvas from "react-signature-canvas";

export const ChangeStatus = () => {
  let params = useParams();
  let history = useHistory();
  const [statut, setStatut] = React.useState("");
  const [dataURL, setDataURL] = React.useState();
  const [modal, setModal] = React.useState("");
  const { success, error } = useToasts();
  const { status, result, doRequest } = useAuthenticatedRequest(
    "PUT /absences/update/" + params.id + "/" + statut,
    {
      manual: true,
      data: { signResponsable: dataURL },
    }
  );

  const resolved = status === statuses.RESOLVED;
  const rejected = status === statuses.REJECTED;
  React.useEffect(() => {
    if (dataURL) {
      setStatut(modal);
      doRequest();
    }
    if (resolved) {
      success(result?.data?.message);
      history.push("/absence-admin");
    }
    if (rejected) {
      error(result?.response?.data?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolved, statut, doRequest, dataURL]);

  let sigPad = React.useRef({});

  function clear() {
    sigPad.current.clear();
  }

  function trim() {
    if (sigPad.current.isEmpty()) {
      error("Il manque une signature !");
    } else {
      setDataURL(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
    }
  }

  return (
    <>
      <button className="green" onClick={() => setModal("accept")}>
        Accepter
      </button>
      <button className="red" onClick={() => setModal("refuse")}>
        Refuser
      </button>

      {modal && (
        <>
          <div onClick={() => setModal("")} className="background-modal" />
          <div className="modal sheet" style={{ height: 600 }}>
            <button onClick={() => setModal("")} className="closeModal">
              <p>&times;</p>
            </button>
            <h1 className="title">
              {modal === "accept" ? "Accepter" : "Refuser"} la demande
            </h1>
            <div className="container-modal">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  trim();
                }}
              >
                <div className="signature">
                  <p>Signature du responsable : </p>
                  <button type="button" onClick={clear}>
                    Effacer
                  </button>
                  <SignatureCanvas ref={sigPad} penColor="black" />
                </div>
                <button
                  type="submit"
                  className={modal === "accept" ? "btn green" : "btn red"}
                >
                  {modal === "accept" ? "Accepter" : "Refuser"}
                </button>
              </form>
            </div>
          </div>
        </>
      )}

      {status === statuses.PENDING && <Loader />}
    </>
  );
};
