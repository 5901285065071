import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { ListUser } from "../../components/ListUser";
import { AddToCalendar } from "./AddToCalendar";
import { isMobileOnly } from "react-device-detect";

export const Sidebar = () => {
  return (
    <>
      <Link className="background-modal" to="/planning" />
      <div className="sidebar">
        <Link to="/planning" className="closeModal">
          <p>&times;</p>
        </Link>

        <h1 className="title">Planning</h1>

        <h2 style={{ margin: 30 }}>Agenda</h2>
        <ListUser me={true} />
        {!isMobileOnly && (
          <Link to="/planning/menu/add-calendar" className="btn add-calendar">
            Ajouter à votre calendrier
          </Link>
        )}
      </div>
      <Switch>
        <Route path="/planning/menu/add-calendar" component={AddToCalendar} />
      </Switch>
    </>
  );
};
