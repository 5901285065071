import React from "react";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Loader } from "../../components/Loader";
import { Error } from "../../components/Error";
import { ListUser } from "../../components/ListUser";
import { Success } from "../../components/Success";
import { useToasts } from "../../components/store/toast-manager";

export const AddInvite = ({
  title,
  startDate,
  endDate,
  type,
  setModalInvite,
  id,
}) => {
  const { success } = useToasts();
  const [invited, setInvited] = React.useState("");
  const [photo, setPhoto] = React.useState("");
  const [dropdown, setDropdown] = React.useState(false);
  const [nom, setNom] = React.useState("");

  const { result, status, doRequest } = useAuthenticatedRequest(
    "POST /events/invite/" + invited,
    {
      manual: true,
      data: {
        title,
        startDate,
        endDate,
        type,
        id,
        nom,
        email: invited,
        photo,
      },
    }
  );
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (invited.includes("cgre.fr")) {
    } else {
      setPhoto("https://api-gestion-interne.fibeer.fr/Photo/user.png");
    }
    if (resolved) {
      success(result?.data.message);
      setModalInvite(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRequest, resolved, invited]);

  return (
    <>
      <div className="background-modal" onClick={() => setModalInvite(false)} />
      <div className="modal sheet" style={{ height: "80%" }}>
        <button onClick={() => setModalInvite(false)} className="closeModal">
          <p>&times;</p>
        </button>
        {status === statuses.PENDING ? (
          <>
            <div className="modal-load_background" />
            <Loader />
          </>
        ) : (
          <>
            <h1 className="title">Ajouter un invité</h1>
            <div className="container-modal">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  doRequest();
                }}
              >
                {dropdown ? (
                  <div className="dropdown">
                    <ListUser
                      me={false}
                      setPhoto={setPhoto}
                      setInvited={setInvited}
                      invited={invited}
                      setNom={setNom}
                    />
                  </div>
                ) : (
                  <input
                    required={dropdown}
                    value=""
                    placeholder="Équipe C.G.Ré"
                    style={{ width: "100%" }}
                    className="input"
                    onClick={() => setDropdown(true)}
                  />
                )}

                <p style={{ textAlign: "center" }}>ou</p>
                <input
                  required={!dropdown}
                  placeholder="Email de l'invité"
                  style={{ width: "100%" }}
                  className="input"
                  type="email"
                  onChange={(e) => setInvited(e.target.value)}
                  value={dropdown ? null : invited}
                />
                <button type="submit">Inviter</button>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};
