import React from "react";
import { Link } from "react-router-dom";
import { ExtractButton } from "./ExtractButton";
import ListeSociete from "./ListeSociété.json";

export const ExtractPage = () => {
  const [societe, setSociete] = React.useState("");
  const [year, setYear] = React.useState("");
  const [type, setType] = React.useState("");

  const [abattement, setAbattement] = React.useState(true);
  const [profil, setProfil] = React.useState(true);

  const liste = ListeSociete.map((item) => (
    <option
      key={item["Code de la societe"] + item["Nom de la societe"]}
      value={item["Code de la societe"] + " - " + item["Nom de la societe"]}
    >
      {item["Nom de la societe"]} - {item["Code de la societe"]}
    </option>
  ));
  return (
    <>
      <Link to="/home" className="background-modal" />
      <div className="modal">
        <Link to="/home" className="closeModal">
          <p>&times;</p>
        </Link>
        <h1 className="title">Extraction</h1>
        <div className="container-modal">
          <p style={{ fontSize: 15, margin: 0, fontWeight: 500 }}>Type :</p>
          <select className="input" onChange={(e) => setType(e.target.value)}>
            <option value=""></option>
            <option value="Extraction">Extraction</option>
          </select>
          <p style={{ fontSize: 15, margin: 0, fontWeight: 500 }}>Société :</p>
          <select
            className="input"
            onChange={(e) => setSociete(e.target.value)}
          >
            <option value=""></option>
            {liste}
          </select>
          <p style={{ fontSize: 15, margin: 0, fontWeight: 500 }}>Année :</p>
          <select className="input" onChange={(e) => setYear(e.target.value)}>
            <option value=""></option>
            <option value="2022,2023">2022</option>
            <option value="2021,2022">2021</option>
            <option value="2020,2021">2020</option>
            <option value="2019,2020">2019</option>
            <option value="2018,2019">2018</option>
            <option value="2017,2018">2017</option>
          </select>
          <div>
            <label style={{ display: "flex" }}>
              <input
                type="checkbox"
                onClick={(e) => setAbattement((on) => !on)}
                checked={abattement}
              />
              <p style={{ marginTop: 10 }}>Type d'abattement</p>
            </label>
            <label style={{ display: "flex" }}>
              <input
                type="checkbox"
                onClick={(e) => setProfil((on) => !on)}
                checked={profil}
              />
              <p style={{ marginTop: 10 }}>Profil du salarié</p>
            </label>
          </div>
          <ExtractButton
            societe={societe}
            year={year}
            type={type}
            abattement={abattement}
            profil={profil}
          />
        </div>
      </div>
    </>
  );
};
