import React from "react";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Link, useLocation } from "react-router-dom";
import { Loader } from "../../components/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import ToggleSwitch from "../../components/ToggleSwitch";
import Select from "react-select";

import { useToasts } from "../../components/store/toast-manager";

registerLocale("fr", fr);

export const AddEvent = ({
  history,
  location: {
    state: { titleCopy, startDateCopy, endDateCopy, typeCopy, publicCopy },
  },
}) => {
  let location = useLocation();

  const [title, setTitle] = React.useState(titleCopy);
  const [startDate, setStart] = React.useState(
    location?.state?.startDateCopy !== "" ? new Date(startDateCopy) : ""
  );
  const [endDate, setEnd] = React.useState(
    location?.state?.endDateCopy !== "" ? new Date(endDateCopy) : ""
  );
  const [toggle, setToggle] = React.useState(publicCopy);
  const [type, setType] = React.useState(
    typeCopy ? { value: typeCopy, label: typeCopy } : ""
  );

  const [jourEntier, setJourEntier] = React.useState(false);

  const { success, error } = useToasts();

  const { status, result, doRequest } = useAuthenticatedRequest(
    "POST /events/create",
    {
      manual: true,
      data: {
        title,
        startDate,
        endDate,
        type: type.value,
        public: !toggle,
      },
    }
  );
  const [noKeyboard, setNoKeyboard] = React.useState(false);
  const resolved = status === statuses.RESOLVED;
  const notResolved = status === statuses.REJECTED;

  React.useEffect(() => {
    if (noKeyboard) {
      setTimeout(() => {
        document.activeElement.blur();
        setNoKeyboard(false);
      }, 10);
    }
    if (resolved) {
      success(result?.data?.message);
      history.push("/planning");
    }
    if (notResolved) {
      error(result?.response?.data.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, resolved, noKeyboard, notResolved]);

  const toggleText = !toggle ? "Public" : "Privé";

  const options = [
    { value: "Rendez-vous", label: "Rendez-vous" },
    { value: "Formation", label: "Formation" },
    { value: "Absence", label: "Absence" },
    { value: "Autre", label: "Autre" },
  ];

  return (
    <>
      <Link to="/planning" className="background-modal" />
      <div className="modal addevent">
        <Link to="/planning" className="closeModal">
          <p>&times;</p>
        </Link>
        {status === statuses.PENDING ? (
          <>
            <div className="modal-load_background" />
            <Loader />
          </>
        ) : (
          <>
            <h1 style={{ marginTop: 90 }} className="title">
              {location.state.titleCopy === ""
                ? "Ajouter un événement"
                : "Dupliquer cette événement"}
            </h1>
            <div className="container-modal">
              <form onSubmit={() => doRequest()}>
                <input
                  required
                  placeholder="Titre de l'événement"
                  style={{ width: "100%" }}
                  className="input"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
                <div onClick={() => setNoKeyboard(true)}>
                  {jourEntier ? (
                    <>
                      <DatePicker
                        required
                        dateFormat="P"
                        withPortal
                        placeholderText="Jour de début"
                        selected={startDate}
                        onChange={(date) => setStart(date)}
                        locale="fr"
                        className="input input-datePicker dateTask"
                      />
                      <DatePicker
                        required
                        dateFormat="P"
                        withPortal
                        placeholderText="Jour de fin"
                        selected={endDate}
                        onChange={(date) => setEnd(date)}
                        locale="fr"
                        className="input input-datePicker dateTask"
                      />
                    </>
                  ) : (
                    <>
                      <DatePicker
                        required
                        dateFormat="Pp"
                        withPortal
                        placeholderText="Date de début"
                        selected={startDate}
                        onChange={(date) => setStart(date)}
                        locale="fr"
                        className="input input-datePicker dateTask"
                        showTimeSelect
                        timeCaption="Heure"
                      />
                      <DatePicker
                        required
                        dateFormat="Pp"
                        withPortal
                        placeholderText="Date de fin"
                        selected={endDate}
                        onChange={(date) => setEnd(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        locale="fr"
                        className="input input-datePicker dateTask"
                        showTimeSelect
                        timeCaption="Heure"
                        disabled={!startDate}
                      />
                    </>
                  )}
                </div>
                <Select
                  isSearchable={false}
                  noOptionsMessage={() => "Ce type n'exsite pas !"}
                  classNamePrefix="react-select"
                  className="select"
                  defaultValue={type}
                  onChange={setType}
                  options={options}
                  placeholder="Type"
                />
                <div style={{ display: "grid" }}>
                  {/* 
                  <ToggleSwitch
                    toggle={jourEntier}
                    setToggle={setJourEntier}
                    text={"Jour entier"}
                  />*/}
                  <ToggleSwitch
                    toggle={toggle}
                    setToggle={setToggle}
                    text={toggleText}
                  />
                </div>
                <button type="submit">Ajouter</button>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};
