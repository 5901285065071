import { useCallback, useEffect, useReducer, useRef } from "react";
import axios from "axios";

const actions = { REQUEST_START: "REQUEST_START", REQUEST_END: "REQUEST_END" };

export const statuses = {
  IDLE: "IDLE",
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED",
};

function reducer(state, action) {
  switch (action.type) {
    case actions.REQUEST_START:
      return { ...state, status: statuses.PENDING };
    case actions.REQUEST_END:
      return {
        result: action.payload,
        status: action.error ? statuses.REJECTED : statuses.RESOLVED,
      };
    default:
      throw new Error(`Action non gérée dans le reducer : ${action.type}`);
  }
}

async function request(config, dispatch) {
  try {
    dispatch({ type: actions.REQUEST_START });
    const response = await axios(config);
    dispatch({ type: actions.REQUEST_END, payload: response });
    return response;
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: actions.REQUEST_END,
        payload: error,
        error: true,
      });
    }
    throw error;
  }
}

function useAxios(config, manual = false) {
  if (!config.url) throw new Error("Aucune URL spécifiée dans la requête");
  const stringifiedConfig = JSON.stringify(config);

  const cancelSourceRef = useRef();

  const [state, dispatch] = useReducer(reducer, {
    status: statuses.IDLE,
    result: null,
  });

  const cancelOutstandingRequest = useCallback(() => {
    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel();
    }
  }, []);

  const withCancelToken = useCallback(
    (config) => {
      cancelOutstandingRequest();

      cancelSourceRef.current = axios.CancelToken.source();

      config.cancelToken = cancelSourceRef.current.token;

      return config;
    },
    [cancelOutstandingRequest]
  );

  const doRequest = useCallback(
    (overrideConfig = {}) => {
      return request(
        withCancelToken({ ...config, ...overrideConfig }),
        dispatch
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedConfig]
  );

  useEffect(() => {
    if (!manual) doRequest().catch(() => {});
    return cancelOutstandingRequest;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manual, doRequest]);

  return { doRequest, ...state };
}
export default useAxios;
