import Axios from "axios";
import React from "react";
import { useParams } from "react-router";
import { API } from "../../../package.json";
import { fetcher } from "../../components/reducer/fetcher";
import { Loader } from "../../components/Loader";
import { Link } from "react-router-dom";
import * as check from "../Invitation/check.json";
import Lottie from "react-lottie";

export const ResetPassword = () => {
  const { id, token } = useParams();
  const [password, setPassword] = React.useState("");
  const [state, dispatch] = React.useReducer(fetcher, {
    loading: false,
    error: null,
    data: {},
  });
  const resetPassword = async (password) => {
    try {
      dispatch({ type: "LOADING" });
      const { data } = await Axios.post(
        API + `auth/reset-password/${id}/${token}`,
        {
          password,
        }
      );
      dispatch({ type: "SUCCESS", payload: data });
    } catch (e) {
      dispatch({ type: "ERROR", payload: e });
    }
  };

  const { data, error, loading } = state;

  const optionCheck = {
    loop: false,
    autoplay: true,
    animationData: check.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  console.log(state);
  return (
    <>
      <div className="modal">
        <div className="form-login" style={{ textAlign: "center" }}>
          {data.id ? (
            <>
              <Lottie options={optionCheck} height={100} width={100} />
              <h2>{data.nom}, votre mot de passe a été modifié !</h2>
              <Link className="btn" to={`/login/${data.id}`}>
                Se connecter
              </Link>
            </>
          ) : (
            <>
              <h2>Changer votre mot de passe</h2>
              <p>Veuillez saisir un mot de passe</p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  resetPassword(password);
                }}
              >
                <input
                  type="password"
                  style={{ width: "100%" }}
                  className="input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Nouveau mot de passe"
                />
                <button type="submit">Valider</button>
              </form>
              {error && (
                <>
                  <p>
                    {error.response.data.message === "jwt expired" &&
                      "Le lien a expiré !"}
                  </p>
                  <Link className="btn" to={`/forget-password/${id}`}>
                    Renvoyer
                  </Link>
                </>
              )}
            </>
          )}

          {loading && <Loader />}
        </div>
      </div>
    </>
  );
};
