import React from "react";
import "react-color-palette/lib/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Link, Switch, useLocation } from "react-router-dom";
import { AdminRoute } from "../..";
import { Loader } from "../../components/Loader";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { getUserInfo } from "../../utils";
import { CreateUser } from "./CreateUser";
import { DetailsUser } from "./DetailsUser";

export const ManagerUser = () => {
  let location = useLocation();
  const { status, result, doRequest } = useAuthenticatedRequest("GET /users");

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRequest, location.pathname]);

  return (
    <>
      <Link to="/profil" className="background-modal" />
      {status === statuses.PENDING && <Loader />}
      <div className="modal">
        <Link to="/profil" className="closeModal">
          <p>&times;</p>
        </Link>
        <h1 className="title">Gérer les utilisateurs</h1>
        <div className="container-modal">
          {result?.data
            ?.filter((user) => !user.id.includes(getUserInfo().id))
            .map((user) => (
              <Link to={`/profil/manager-user/${user.id}`} key={user.id}>
                <li>
                  <div className="round-account">
                    <img src={user.photo} alt="" />
                  </div>
                  <p>{user.nom}</p>
                  <span
                    style={{
                      backgroundColor: user.color,
                      width: 10,
                      height: 10,
                      margin: 16,
                      marginLeft: 10,
                    }}
                    className="circle"
                  />
                </li>
              </Link>
            ))}
          <Link to="/profil/manager-user/create" className="btn">
            Créer un utilisateur
          </Link>
        </div>
      </div>
      <Switch>
        <AdminRoute path="/profil/manager-user/create" component={CreateUser} />
        <AdminRoute path="/profil/manager-user/:id" component={DetailsUser} />
      </Switch>
    </>
  );
};
