import React from "react";
import Axios from "axios";
import { API } from "../../../package.json";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "../../components/store/toast-manager";

export const UploadPhoto = () => {
  const { success, error: errorToast } = useToasts();
  const [file, setFile] = React.useState(null);

  let history = useHistory();

  function upload() {
    const formData = new FormData();
    formData.append("myImage", file);
    const jwt = localStorage.getItem("jwt");
    const config = {
      headers: {
        "content-type": "image/jpeg; image/png",
        Authorization: `Bearer ${jwt}`,
      },
    };
    Axios.post(API + "users/upload", formData, config)
      .then((response) => {
        success(response?.data.message);
        history.push("/profil");
      })
      .catch((e) => {
        errorToast(e.response.data.message);
      });
  }

  return (
    <>
      <Link to="/profil" className="background-modal" />
      <div style={{ height: 350 }} className="modal sheet">
        <h1 className="title">Changer votre photo</h1>
        <Link to="/profil" className="closeModal">
          <p>&times;</p>
        </Link>
        <div className="container-modal">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              upload();
            }}
          >
            <input
              required
              onChange={(e) => setFile(e.target.files[0])}
              type="file"
              name="file"
              id="file"
              className="inputfile"
              accept="image/png, image/jpeg"
            />
            <label style={{ cursor: "pointer" }} htmlFor="file">
              {file ? file.name : "Choisir votre photo"}
            </label>

            <button type="submit">Modifier</button>
          </form>
        </div>
      </div>
    </>
  );
};
