import React from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { Loader } from "../../../components/Loader";
import { useToasts } from "../../../components/store/toast-manager";
import { useAuthenticatedRequest } from "../../../components/store/use-api";
import { statuses } from "../../../components/store/use-axios";

export const Linkedin = () => {
  const [subject, setSubject] = React.useState("");
  const [clients, setClients] = React.useState([]);
  const { status, result } = useAuthenticatedRequest("GET /mail/getPost");
  let history = useHistory();
  const options = [
    {
      value:
        "fereolcompta@gmail.com;amelie.loaec@assistsecurite.com;melanie.castel@assist-securite.com;marie.odile.tregoat@assistsecurite.com;soniamichaud@hauraix.com;gcompagnon@feelingexpress.fr;mbriens@feelingexpress.fr;valerieleclercq@hauraix.com;olivierhauraix@hauraix.com;sandrine@alro-transports.fr;olivierhauraix@hauraix.com;amontier@most-transport.fr;comptabilite@tpsmost.fr;f.tourneux@oursonbleu.fr;s.grenier@oursonbleu.fr;b.tourneux@oursonbleu.fr;k.barros@oursonbleu.fr;j.grenier@tcmidf.fr;s.grenier@oursonbleu.fr;i.tirel@tcb22.fr;administratif@tcb22.fr;v.molto@taf33.com;bureau1@taf33.com;slecabec@transportsburlot.fr;sburlot@transportsburlot.fr;guillaume.delcroix@transportsdelcroix-orleans.fr;karen.barillet@transportsdelcroix-orleans.fr;",
      label: "Portail",
    },
    {
      value:
        "aurelie@ambulance-delort.fr;pfavrais@groupeleduff.com;asmustiere@groupeleduff.com;frenault@groupe-tardet.com;transportsducouesnon@gmail.com;comptabilite@transports-marechal.fr;gwenaelle.binet@sohier.fr;cgoasdoue@video-injection.com;",
      label: "Pass",
    },
    {
      value:
        "2avoyages@gmail.com;bouder2avgestionpaie@gmail.com;evadezvous@gmail.com;direction.dys@orange.fr;dani.holding@orange.fr;direction.dys@orange.fr;dani.holding@orange.fr;cathylependeven@gmail.com;erwan.huon@movendi.fr;direction.dys@orange.fr;dani.holding@orange.fr;administratif@taranis-transports.fr;rh@taranis-transports.fr;cousseau.fils@wanadoo.fr;administratif@transportscousseaufils.fr;facturation@transportscousseaufils.fr;compta@martinjeanetfils.fr;bernard@martinjeanetfils.fr;borecompta@sfr.fr;cathylependeven@gmail.com;trans.agri.breizh@orange.fr;",
      label: "Externalisation",
    },
  ];
  const { success } = useToasts();
  const {
    status: statusPost,
    result: resultPost,
    doRequest: doRequestPost,
  } = useAuthenticatedRequest("POST /mail/send", {
    manual: true,
    data: {
      subject,
      data: result?.data?.post,
      image: result?.data?.image,
      clients: clients.map((item) => item.value),
    },
  });
  const resolved = statusPost === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      success("Post envoyé !");
      history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, resolved]);

  return (
    <>
      <Link to="/home" className="background-modal" />
      <div className="modal modal-full">
        <Link to="/home" className="closeModal">
          <p>&times;</p>
        </Link>
        <h1 className="title">Partage LinkedIn</h1>
        <div className="parent" style={{ height: "80%" }}>
          <div className="left">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: 50,
                flexWrap: "wrap",
              }}
            >
              <Select
                isSearchable={false}
                noOptionsMessage={() => "Vous avez tout sélectionné !"}
                classNamePrefix="react-select"
                className="select marginBottom"
                isMulti
                defaultValue={clients}
                onChange={setClients}
                options={options}
                placeholder="Clients"
                style={{ marginBottom: 10 }}
              />
              <input
                className="input"
                placeholder="Objet du mail"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
              <button
                style={{ marginTop: 100 }}
                className={
                  subject && clients && result?.data?.post ? null : "disabled"
                }
                onClick={() => doRequestPost()}
              >
                Envoyer
              </button>
            </div>
          </div>
          <div className="right" style={{ overflowY: "scroll" }}>
            {status === statuses.PENDING && (
              <>
                <div style={{ marginTop: 40 }}>
                  <Loader inContainer={true} />
                  <p style={{ textAlign: "center" }}>Récupération du post...</p>
                </div>
              </>
            )}
            {statusPost === statuses.PENDING && <Loader />}
            {result?.data && (
              <>
                <div style={{ width: "60%", margin: "auto" }}>
                  <img
                    alt=""
                    src="https://api-gestion-interne.fibeer.fr/images/logo.png"
                    style={{
                      display: "block",
                      width: 100,
                      marginTop: 50,
                      marginBottom: 50,
                      margin: "auto",
                    }}
                  />
                  <h1>{subject}</h1>
                  <div
                    dangerouslySetInnerHTML={{ __html: result?.data?.post }}
                  />
                  <img width="100%" src={result?.data?.image} alt="" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
