import React from "react";
import { Iconly } from "react-iconly";
import { useHistory } from "react-router-dom";
import { getUserInfo } from "../../utils";
import { ChangeStatus } from "./ChangeStatus";
import { DelTask } from "./DelTask";

export const Task = (props) => {
  let history = useHistory();
  const { id, date_limite, statut } = props?.data;
  const [popover, setPopover] = React.useState(false);

  return (
    <>
      <div
        className={
          new Date() >= new Date(date_limite) && statut !== "ok"
            ? "card-item priorite"
            : "card-item"
        }
      >
        {popover && (
          <div onClick={() => setPopover(false)} className="backdrop-element" />
        )}
        <div
          className="task"
          onClick={
            getUserInfo().role === "admin"
              ? () => history.push("/task-admin/updatetask/" + id)
              : () => history.push("/missions/updatetask/" + id)
          }
        >
          <p className="task-title">
            {new Date(props.data.date_creation) >=
              new Date() - 10000 * 60 * 6 && (
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 31.955 31.955"
                className="new-task"
              >
                <g>
                  <path
                    d="M27.25,4.655C20.996-1.571,10.88-1.546,4.656,4.706C-1.571,10.96-1.548,21.076,4.705,27.3
c6.256,6.226,16.374,6.203,22.597-0.051C33.526,20.995,33.505,10.878,27.25,4.655z"
                  />
                  <path
                    d="M13.288,23.896l-1.768,5.207c2.567,0.829,5.331,0.886,7.926,0.17l-0.665-5.416
C17.01,24.487,15.067,24.5,13.288,23.896z M8.12,13.122l-5.645-0.859c-0.741,2.666-0.666,5.514,0.225,8.143l5.491-1.375
C7.452,17.138,7.426,15.029,8.12,13.122z M28.763,11.333l-4.965,1.675c0.798,2.106,0.716,4.468-0.247,6.522l5.351,0.672
C29.827,17.319,29.78,14.193,28.763,11.333z M11.394,2.883l1.018,5.528c2.027-0.954,4.356-1.05,6.442-0.288l1.583-5.137
C17.523,1.94,14.328,1.906,11.394,2.883z"
                  />
                  <circle cx="15.979" cy="15.977" r="6.117" />
                </g>
              </svg>
            )}
            {props.data.type} : {props.data.dossier}
          </p>
          <div
            style={{ marginLeft: 0, height: 30, width: 30 }}
            className="round-account task-people"
          >
            <img src={props.data.photoUser} alt="" />
          </div>
          <p className="task-description">{props.data.description}</p>
          <div className="task-date" style={{ display: "flex" }}>
            <Iconly name="Calendar" primaryColor="#6a6a6a" set="light" />
            {props.data.statut === "ok" ? (
              <p style={{ marginLeft: 10 }}>
                Mission terminée le{" "}
                {new Date(props.data.date_fin).toLocaleDateString()}
              </p>
            ) : (
              <p style={{ marginLeft: 10 }}>
                Date limite :{" "}
                {new Date(props.data.date_limite).toLocaleDateString()}
                {new Date() >= new Date(date_limite) && " Urgent"}
              </p>
            )}
          </div>
        </div>
        <button onClick={() => setPopover((on) => !on)} className="task-more">
          <Iconly
            name="MoreCircle"
            primaryColor="#6a6a6a"
            size="large"
            set="light"
          />
        </button>
        {popover && (
          <div className="popover">
            <ul>
              <ChangeStatus id={id} statut={statut} />
              <DelTask id={id} />
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
