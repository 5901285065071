import React from "react";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Error } from "../../components/Error";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { Success } from "../../components/Success";
import { Loader } from "../../components/Loader";
registerLocale("fr", fr);

export const ButtonImport = (props) => {
  const [successAdd, setSuccessAdd] = React.useState(false);

  const { status, result, doRequest } = useAuthenticatedRequest(
    "POST /tasks/import",
    {
      manual: true,
      data: props.data,
    }
  );

  function back(url) {
    return props.history.push(url);
  }

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      setSuccessAdd(true);
      setTimeout(() => {
        back("/missions");
      }, 1600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolved]);

  const text = "Les tâches ont été importées";

  function condition(condition) {
    return props.data && !JSON.stringify(props.data).includes(condition);
  }
  
  return (
    <>
      {successAdd && <Success text={text} />}
      {status === statuses.PENDING && (
        <>
          <div className="modal-load_background" />
          <Loader />
        </>
      )}

      <button
        style={{ position: "absolute", bottom: 0, left: 0 }}
        className={
          props.data &&
          condition('"date_limite":""') &&
          condition('"type":""') &&
          condition('"statut":""') &&
          condition('"dossier":""') &&
          !condition("date_limite") &&
          !condition("id")
            ? "btn-updateTask"
            : "btn-updateTask disabled"
        }
        onClick={() => doRequest()}
      >
        Importer
      </button>

      {status === statuses.REJECTED && (
        <Error result={result} doRequest={doRequest} />
      )}
    </>
  );
};
