import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import { Logo } from "../../components/Logo/Logo";
import { EventItem } from "../Planning/EventItem";
import { Formation } from "./Formation";
import { Rdv } from "./Rdv";
import { isBrowser } from "react-device-detect";
import { ExtractPage } from "../Extraction/ExtractPage";
import { getUserInfo } from "../../utils";
import { AdminRoute } from "../..";
import { SideMenu } from "./SideMenu";
import { Mailing } from "./Mailing/Mailing";
import { Linkedin } from "./Mailing/Linkedin";

export const Home = () => {
  return (
    <>
      {isBrowser && (
        <div className="logo home">
          <Logo />
        </div>
      )}
      <h1
        style={
          isBrowser ? { marginTop: 120 } : { marginTop: 40, textAlign: "left" }
        }
        className="title"
      >
        Bonjour, <label className="title">{getUserInfo().nom} !</label>
      </h1>

      <div className="container home" style={{ marginTop: 40 }}>
        <Rdv />
        <Formation />
      </div>
      <Switch>
        <Route path="/home/mailing/linkedin" component={Linkedin} />
        <Route path="/home/mailing" component={Mailing} />
        <Route path="/home/menu" component={SideMenu} />
        <Route path="/home/event/:id" component={EventItem} />
        <AdminRoute path="/home/extraction" component={ExtractPage} />
      </Switch>
    </>
  );
};
