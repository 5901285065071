import React from "react";
import { Link, useParams } from "react-router-dom";
import { Error } from "../../components/Error";
import { Loader } from "../../components/Loader";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { getUserInfo } from "../../utils";
import { ChangeStatus } from "./ChangeStatus";
import { DeleteAbsence } from "./DeleteAbsence";
import { DownloadPDF } from "./Pdf";

export const AbsenceItem = () => {
  const params = useParams();
  const { status, result, doRequest } = useAuthenticatedRequest(
    "GET /absences/" + params.id
  );

  const { result: resultUser, doRequest: doRequestUser } =
    useAuthenticatedRequest("GET /users/" + result?.data?.idUser, {
      manual: true,
    });

  const resolved = status === statuses.RESOLVED;

  React.useEffect(() => {
    if (resolved) {
      doRequestUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRequest, resolved]);

  return (
    <>
      {status === statuses.REJECTED ? null : (
        <>
          <Link
            to={getUserInfo().role === "admin" ? "/absence-admin" : "/absence"}
            className="background-modal"
          />
          <div className="modal">
            <Link
              to={
                getUserInfo().role === "admin" ? "/absence-admin" : "/absence"
              }
              className="closeModal"
            >
              <p>&times;</p>
            </Link>
            {status === statuses.PENDING ? (
              <Loader />
            ) : (
              <>
                <div className="container-modal">
                  {result?.data === "" ? (
                    <>
                      <h3>La demande d'absence a été supprimé !</h3>
                      <Link
                        className="btn"
                        to={
                          getUserInfo().role === "admin"
                            ? "/absence-admin"
                            : "/absence"
                        }
                      >
                        Retour
                      </Link>
                    </>
                  ) : (
                    <>
                      <h3>{result?.data?.type}</h3>

                      <div style={{ display: "flex" }}>
                        <div
                          style={{ marginLeft: 0, height: 30, width: 30 }}
                          className="round-account"
                        >
                          <img src={resultUser?.data?.photo} alt="" />
                        </div>
                        <p style={{ color: "#4A4A4A", fontSize: 12 }}>
                          Demandé par <b>{resultUser?.data?.nom}</b> le{" "}
                          <b>
                            {new Date(
                              result?.data?.date_creation
                            ).toLocaleDateString()}
                          </b>
                        </p>
                      </div>
                      <p>
                        Status :{" "}
                        <b
                          style={
                            result?.data?.status === "pending"
                              ? { color: "#ff8e25" }
                              : result?.data?.status === "accept"
                              ? { color: "#93c93c" }
                              : { color: "#ff004d" }
                          }
                        >
                          {result?.data?.status === "pending"
                            ? "En attente"
                            : result?.data?.status === "accept"
                            ? "Accepté"
                            : "Refusé"}
                        </b>{" "}
                        {result?.data?.status !== "pending" && (
                          <>
                            le{" "}
                            <b>
                              {new Date(
                                result?.data?.dateStatus
                              ).toLocaleDateString()}
                            </b>
                          </>
                        )}
                      </p>
                      <p>
                        Du{" "}
                        <b>
                          {new Date(
                            result?.data?.startDate
                          ).toLocaleDateString()}{" "}
                        </b>
                        au{" "}
                        <b>
                          {" "}
                          {new Date(
                            result?.data?.retourDate
                          ).toLocaleDateString()}
                        </b>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          margin: "auto",
                          justifyContent: "left",
                        }}
                      >
                        <div>
                          <h4 style={{ fontSize: 14 }}>
                            Signature du demandeur :
                          </h4>
                          <img
                            style={{ width: 100, height: 100 }}
                            src={result?.data?.signature}
                            alt="signature"
                          />
                        </div>
                        {result?.data?.status !== "pending" && (
                          <div style={{ marginLeft: 50 }}>
                            <h4 style={{ fontSize: 14 }}>
                              Signature du responsable :
                            </h4>

                            <img
                              style={{ width: 100, height: 100 }}
                              src={result?.data?.signResponsable}
                              alt="signature"
                            />
                          </div>
                        )}
                      </div>

                      {result?.data?.status !== "pending" && (
                        <DownloadPDF result={result?.data} />
                      )}

                      {result?.data?.status === "pending" &&
                        result?.data?.idUser === getUserInfo().id && (
                          <DeleteAbsence />
                        )}
                      {result?.data?.status === "pending" &&
                        getUserInfo().role === "admin" && <ChangeStatus />}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
