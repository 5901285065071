import React from "react";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Link } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { Error } from "../../components/Error";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { useToasts } from "../../components/store/toast-manager";
import { getUserInfo } from "../../utils";
import { ListUser } from "../../components/ListUser";
registerLocale("fr", fr);

export const AddTaskAdmin = ({ history }) => {
  const [lien_ged, setLien_ged] = React.useState("");
  const [dossier, setDossier] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [type, setType] = React.useState("");

  const [sous_type, setsous_type] = React.useState("");
  const [quantite, setQuantite] = React.useState(0);
  const [dateLimite, setDateLimite] = React.useState(
    new Date(new Date().getTime() + 3000 * 60 * 60 * 24)
  );
  const date_limite = parseDate(new Date(dateLimite).toLocaleDateString());

  function parseDate(dateStr) {
    const matchFrenchDate = dateStr.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (!matchFrenchDate) throw new SyntaxError("Invalid Date");
    const [, day, month, year] = matchFrenchDate;
    return new Date([year, month, day].join("-"));
  }

  const { success } = useToasts();

  const [idUser, setIdUser] = React.useState("");

  const { status, result, doRequest } = useAuthenticatedRequest(
    "POST /tasks/createAdmin",
    {
      manual: true,
      data: {
        idUser,
        lien_ged,
        dossier,
        description,
        type,
        sous_type,
        date_limite,
        quantite: parseInt(quantite),
      },
    }
  );

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      success("Mission ajoutée !");
      history.push("/task-admin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, resolved]);

  return (
    <>
      <Link
        to={getUserInfo().role === "admin" ? "/task-admin" : "/missions"}
        className="background-modal"
      />
      <div className="modal">
        <Link
          to={getUserInfo().role === "admin" ? "/task-admin" : "/missions"}
          className="closeModal"
        >
          <p>&times;</p>
        </Link>
        {status === statuses.PENDING ? (
          <>
            <div className="modal-load_background" />
            <Loader />
          </>
        ) : (
          <>
            <div className="container-modal">
              <h1 className="title">Ajouter une mission</h1>
              {getUserInfo().role === "admin" && (
                <>
                  <p>Personne * :</p>
                  <ListUser me={false} setIdUser={setIdUser} idUser={idUser} />
                </>
              )}
              <p>Dossier * :</p>
              <input
                className="input"
                type="text"
                onChange={(e) => setDossier(e.target.value)}
                value={dossier}
              />
              <p>Type * :</p>
              <div className="select">
                <select onChange={(e) => setType(e.target.value)}>
                  <option value=""></option>
                  <option value="Arret de Travail">Arret de travail</option>
                  <option value="Solde de tout compte">
                    Solde de tout compte
                  </option>
                  <option value="Embauche">Embauche</option>
                  <option value="Pass">Pass</option>
                  <option value="Portail">Portail</option>
                  <option value="Externalisation et SOS Paie">
                    Externalisation et SOS Paie
                  </option>
                  <option value="Sauvegarde">Sauvegarde</option>
                  <option value="Autres">Autres</option>
                </select>
              </div>
              {type && (
                <>
                  <p>Sous-type :</p>
                  <div className="select">
                    {type === "Arret de Travail" && (
                      <select onChange={(e) => setsous_type(e.target.value)}>
                        <option value=""></option>
                        <option value="DSN">DSN</option>
                        <option value="Depot">Depot</option>
                      </select>
                    )}
                    {type === "Solde de tout compte" && (
                      <select onChange={(e) => setsous_type(e.target.value)}>
                        <option value=""></option>
                        <option value="Bulletin STC">Bulletin STC</option>
                        <option value="Depot">Depot</option>
                      </select>
                    )}
                    {type === "Embauche" && (
                      <select onChange={(e) => setsous_type(e.target.value)}>
                        <option value=""></option>
                        <option value="Contrat">Contrat</option>
                        <option value="DPAE">DPAE</option>
                        <option value="Embauche Pégase">Embauche Pégase</option>
                        <option value="Autre">Autre</option>
                      </select>
                    )}
                    {type === "Portail" && (
                      <select onChange={(e) => setsous_type(e.target.value)}>
                        <option value=""></option>
                        <option value="Contrat">Assistance ++</option>
                      </select>
                    )}
                    {type === "Externalisation et SOS Paie" && (
                      <select onChange={(e) => setsous_type(e.target.value)}>
                        <option value=""></option>
                        <option value="Préparation du fichier navette">
                          Préparation du fichier navette
                        </option>
                        <option value="Réalisation des Bulletins de salaire">
                          Réalisation des Bulletins de salaire
                        </option>
                        <option value="Bulletin rectificatif">
                          Bulletin rectificatif
                        </option>
                        <option value="Solde de tout compte">
                          Solde de tout compte
                        </option>
                        <option value="Décomposition des heures">
                          Décomposition des heures
                        </option>
                        <option value="Suivi des RC et/ou RCR">
                          Suivi des RC et/ou RCR
                        </option>
                        <option value="Etat des CP au basculement et fractionnement">
                          Etat des CP au basculement et fractionnement
                        </option>
                        <option value="Etat des effectifs">
                          Etat des effectifs
                        </option>
                        <option value="Fichier de virement">
                          Fichier de virement
                        </option>
                        <option value="DSN (Mensuelle)">DSN (mensuelle)</option>
                        <option value="Déclaration DSN mensuelle">
                          Déclaration DSN mensuelle
                        </option>
                        <option value="Dépot DSN mensuelle">
                          Dépot DSN mensuelle
                        </option>
                        <option value="Déclaration des Accidents du Travail">
                          Déclaration des Accidents du Travail
                        </option>
                        <option value="Suivi des IJSS">Suivi des IJSS</option>
                        <option value="Dossier prise en charge maladie par prévoyance">
                          Dossier prise en charge maladie par prévoyance
                        </option>
                        <option value="Suivi des saisies-arrêt">
                          Suivi des saisies-arrêt
                        </option>
                        <option value="Calcul garantie annuelle de rémunération">
                          Calcul garantie annuelle de rémunération
                        </option>
                        <option value="Autres">Autres</option>
                      </select>
                    )}
                    {type === "Sauvegarde" && (
                      <select onChange={(e) => setsous_type(e.target.value)}>
                        <option value=""></option>
                        <option value="Bulletin de paie (PDF)">
                          Bulletin de paie (PDF)
                        </option>
                        <option
                          value="Bulletin en version non clarifiée (détail des
                        cotisations - tarif à l'envoi global)"
                        >
                          Bulletin en version non clarifiée (détail des
                          cotisations - tarif à l'envoi global)
                        </option>
                        <option value="Transmission des étas des règlements (PDF)">
                          Transmission des étas des règlements (PDF)
                        </option>
                        <option value="Journal des salaires (PDF)">
                          Journal des salaires (PDF)
                        </option>
                        <option value="Tableaux des charges par caisse mensuels et trimestriels (PDF)">
                          Tableaux des charges par caisse mensuels et
                          trimestriels (PDF)
                        </option>
                        <option value="Fichiers DSN mensuelle + justificatif (PDF)">
                          Fichiers DSN mensuelle + justificatif (PDF)
                        </option>
                        <option value="Etat des paiements organismes (PDF)">
                          Etat des paiements organismes (PDF)
                        </option>
                        <option value="Journal des coûts salariaux (PDF)">
                          Journal des coûts salariaux (PDF)
                        </option>
                        <option value="Journal des coûts salariaux (Excel)">
                          Journal des coûts salariaux (Excel)
                        </option>
                        <option value="Journal des OD de paie (PDF)">
                          Journal des OD de paie (PDF)
                        </option>
                        <option
                          value="Fourniture du fichier d'OD de paie transférable en
                        comptabilité"
                        >
                          Fourniture du fichier d'OD de paie transférable en
                          comptabilité
                        </option>
                        <option value="Extractions mensuelles sous format Excel à la demande">
                          Extractions mensuelles sous format Excel à la demande
                        </option>
                        <option value="Provision des congés payés à la date de basculement">
                          Provision des congés payés à la date de basculement
                        </option>
                        <option value="Registre d'entrée et sortie du personnel (PDF)">
                          Registre d'entrée et sortie du personnel (PDF)
                        </option>
                        <option
                          value="Etat des retenues sur salaire (saisie, pension
                        alimentaire, prêt, opposition sur salaire)"
                        >
                          Etat des retenues sur salaire (saisie, pension
                          alimentaire, prêt, opposition sur salaire)
                        </option>
                        <option value="Etat des trop perçu en PDF">
                          Etat des trop perçu en PDF
                        </option>
                        <option value="Etat complémentaire pour bilan comptable">
                          Etat complémentaire pour bilan comptable
                        </option>
                      </select>
                    )}
                  </div>
                </>
              )}
              <p>Lien GED :</p>
              <input
                className="input"
                type="text"
                onChange={(e) => setLien_ged(e.target.value)}
                value={lien_ged}
              />
              <p>Description :</p>
              <textarea
                type="text"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
              <p>Date limite :</p>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={dateLimite}
                onChange={(date) => setDateLimite(date)}
                locale="fr"
                className="input input-datePicker dateTask"
              />
              <p>Quantité :</p>
              <input
                className="input"
                type="number"
                required
                onChange={(e) => setQuantite(e.target.value)}
                value={quantite}
              />
              <button
                className={dossier && type ? "btn-updateTask" : "disabled"}
                onClick={() => doRequest()}
              >
                Ajouter
              </button>
              {status === statuses.REJECTED && (
                <Error result={result} doRequest={doRequest} />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
