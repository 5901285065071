import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import "react-color-palette/lib/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { statuses } from "../../components/store/use-axios";
import { useToasts } from "../../components/store/toast-manager";

export const UpdateColor = ({
  location: {
    state: { couleur },
  },
}) => {
  const [color, setColor] = useState(couleur);
  const { success, error } = useToasts();
  let history = useHistory();

  const { result: resultUsers } = useAuthenticatedRequest("GET /users");

  const { status, result, doRequest } = useAuthenticatedRequest(
    "POST /users/update-color",
    {
      manual: true,
      data: {
        color: color,
      },
    }
  );
  const resolved = status === statuses.RESOLVED;
  const rejected = status === statuses.REJECTED;
  React.useEffect(() => {
    if (resolved) {
      success(result?.data.message);
      history.push("/profil");
    }
    if (rejected) {
      error(
        result?.response ? result?.response?.data.message : "Pas de connexion !"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolved, rejected]);

  const colors = [
    { color: "#30c4f5" },
    { color: "#93C93C" },
    { color: "#ff8e25" },
    { color: "#ef5a9b" },
    { color: "#662C91" },
    { color: "#D72638" },
    { color: "#0267C1" },
    { color: "#775144" },
    { color: "#AF2BBF" },
    { color: "#171A21" },
    { color: "#03256C" },
    { color: "#23CBD4" },
    { color: "#8A4FFF" },
    { color: "#F1E130" },
    { color: "#AB87FF" },
  ];

  return (
    <>
      <Link to="/profil" className="background-modal" />
      <div style={{ height: "70%" }} className="modal sheet">
        <h1 className="title">Changer votre couleur</h1>
        <Link to="/profil" className="closeModal">
          <p>&times;</p>
        </Link>
        <div className="container-modal">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              doRequest();
            }}
          >
            <div className="color-picker">
              {colors.map((item) => (
                <div
                  key={item.color}
                  onClick={() => setColor(item.color)}
                  style={{ backgroundColor: item.color }}
                >
                  <div className="container-color-picker">
                    {resultUsers?.data?.map(
                      (user) =>
                        user.color === item.color && (
                          <div key={user.id} className="round-account">
                            <img src={user.photo} alt="" />
                          </div>
                        )
                    )}
                    {item.color === color && <div className="check" />}
                  </div>
                </div>
              ))}
            </div>

            <button type="submit">Modifier</button>
          </form>
        </div>
      </div>
    </>
  );
};
