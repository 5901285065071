import React from "react";
import { Iconly } from "react-iconly";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { getUserInfo } from "../utils";
import { Error } from "./Error";
import { useAuthenticatedRequest } from "./store/use-api";
import { statuses } from "./store/use-axios";

export const TabBar = () => {
  let location = useLocation();
  let history = useHistory();
  const [start, setStart] = React.useState(false);
  const { status, result, doRequest } =
    useAuthenticatedRequest("GET /users/me");
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (start) {
      setStart(false);
      doRequest();
      history.push("/home");
    }
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRequest, start]);
  return (
    <>
      {result?.data?.color === "#000" ||
      result?.data?.photo ===
        "https://api-gestion-interne.fibeer.fr/Photo/user.png" ? (
        <>
          <div className="background-modal" />
          <div className="modal sheet" style={{ height: 500 }}>
            <h1 className="title">
              Bienvenue <b>{result?.data?.nom}</b> !
            </h1>
            <div className="container-modal">
              <p>
                Veuillez choisir votre couleur de planning et votre photo de
                profil !
              </p>
              <Link
                style={{ display: "flex" }}
                to={{
                  pathname: "/profil/update-color",
                  state: {
                    couleur: result?.data?.color,
                  },
                }}
              >
                <span
                  className="change-circle"
                  style={{ marginTop: 20, marginLeft: 10 }}
                >
                  <Iconly
                    name="Edit"
                    primaryColor="#000"
                    set="light"
                    size="medium"
                  />
                </span>
                <p style={{ marginTop: 32, marginLeft: 20 }}>
                  Choisir ma couleur
                </p>
              </Link>
              <Link
                style={{ display: "flex" }}
                to={{
                  pathname: "/profil/update-photo",
                }}
              >
                <span
                  className="change-circle"
                  style={{ marginTop: 20, marginLeft: 10 }}
                >
                  <Iconly
                    name="Edit"
                    primaryColor="#000"
                    set="light"
                    size="medium"
                  />
                </span>
                <p style={{ marginTop: 32, marginLeft: 20 }}>
                  Choisir ma photo
                </p>
              </Link>
              <button className="btn" onClick={() => setStart(true)}>
                Commencer
              </button>
            </div>
          </div>
        </>
      ) : null}
      {status === statuses.REJECTED && (
        <Error result={result} doRequest={doRequest} />
      )}
      <nav className="tabBar">
        <ul>
          <li>
            <Link to="/home">
              <Iconly
                name="Home"
                primaryColor={
                  location.pathname.includes("/home") ? "#30c4f5" : "#000"
                }
                set={location.pathname.includes("/home") ? "bold" : "light"}
                size="large"
              />
            </Link>
          </li>
          <li>
            <Link to="/planning">
              <Iconly
                name="Calendar"
                primaryColor={
                  location.pathname.includes("/planning") ? "#30c4f5" : "#000"
                }
                set={location.pathname.includes("/planning") ? "bold" : "light"}
                size="large"
              />
            </Link>
          </li>
          <li>
            <Link
              to={getUserInfo().role === "admin" ? "/task-admin" : "/missions"}
            >
              <Iconly
                name="Document"
                primaryColor={
                  location.pathname.includes("/missions") ||
                  location.pathname.includes("/task")
                    ? "#30c4f5"
                    : "#000"
                }
                set={
                  location.pathname.includes("/missions") ||
                  location.pathname.includes("/task")
                    ? "bold"
                    : "light"
                }
                size="large"
              />
            </Link>
          </li>

          <li>
            <Link
              to={
                getUserInfo().role === "admin" ? "/absence-admin" : "/absence"
              }
            >
              <Iconly
                name="Bag2"
                primaryColor={
                  location.pathname.includes("/absence") ? "#30c4f5" : "#000"
                }
                set={location.pathname.includes("/absence") ? "bold" : "light"}
                size="large"
              />
            </Link>
          </li>
          <li>
            <Link to="/profil">
              <div className="round-account">
                <img src={result?.data?.photo} alt="" />
              </div>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};
