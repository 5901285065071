import React from "react";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { useToasts } from "../../components/store/toast-manager";

export const DeleteInvite = ({ id, setSuccess }) => {
  const { success } = useToasts();
  const { status, result, doRequest } = useAuthenticatedRequest(
    "DELETE /events/invitation/" + id,
    {
      manual: true,
    }
  );

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      success(result?.data.message);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolved]);

  return (
    <>
      <button
        style={{
          padding: 0,
          width: 40,
          height: 40,
          fontSize: 25,
          fontWeight: 400,
          marginTop: 5,
          position: "absolute",
          right: 30,
        }}
        onClick={() => doRequest()}
        className="red"
      >
        &times;
      </button>
    </>
  );
};
