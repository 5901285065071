import Axios from "axios";
import React from "react";
import Lottie from "react-lottie";
import { useHistory, useLocation, useParams } from "react-router";
import { API } from "../../../package.json";
import { fetcher } from "../../components/reducer/fetcher";
import * as check from "../Invitation/check.json";
import { Loader } from "../../components/Loader";

export const ForgetPassword = () => {
  let location = useLocation();
  const { id } = useParams();
  let history = useHistory();
  const [state, dispatch] = React.useReducer(fetcher, {
    loading: false,
    error: null,
    data: {},
  });
  React.useEffect(() => {
    const forgetPassword = async () => {
      try {
        dispatch({ type: "LOADING" });
        const { data } = await Axios.post(API + "auth/forget-password/" + id);
        dispatch({ type: "SUCCESS", payload: data });
        setTimeout(() => {
          history.push("/login/" + id);
        }, 10000);
      } catch (e) {
        dispatch({ type: "ERROR", payload: e });
      }
    };
    forgetPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { data, error, loading } = state;

  const optionCheck = {
    loop: false,
    autoplay: true,
    animationData: check.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="form-login" style={{ textAlign: "center" }}>
        {data.messageId && (
          <>
            <Lottie options={optionCheck} height={100} width={100} />
            <h2>Un mail a été envoyé sur votre boite mail !</h2>
            <p>Le lien de demande de mot de passe expire dans 30 minutes</p>
          </>
        )}
        {loading && <Loader />}
        {error && <p>Erreur lors de l'envoie..</p>}
      </div>
    </>
  );
};
