import React from "react";
import ReactDOM from "react-dom";

import { Absences } from "./pages/Absence/Absences";
import { SelectProfil } from "./pages/Auth/SelectProfil";
import { Login } from "./pages/Auth/Login";
import { Loader } from "./components/Loader";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import "./style/style.css";
import { TabBar } from "./components/TabBar";
import { Calendar } from "./pages/Planning/Calendar";
import { Profil } from "./pages/Profil/Profil";
import { Invitation } from "./pages/Invitation/Invitation";
import { ForgetPassword } from "./pages/Auth/ForgetPassword";
import { ResetPassword } from "./pages/Auth/ResetPassword";
import createLocalStorageStateHook from "use-local-storage-state";
import { TaskList } from "./pages/Task/TaskList";
import { Home } from "./pages/Home/Home";
import { ToastProvider } from "./components/store/toast-manager";
import { getUserInfo } from "./utils";
import { AbsenceAdmin } from "./pages/Absence/AbsenceAdmin";
import * as serviceWorker from "./serviceWorker";
import { TaskAdmin } from "./pages/Task/TaskAdmin";

export const usePersonne = createLocalStorageStateHook("personne", "");

function App() {
  let location = useLocation();
  return (
    <>
      {localStorage.getItem("jwt") && <TabBar />}
      <Switch location={location}>
        <Route path="/invitation/:id/:status" component={Invitation} />
        <Route path="/login/:id" component={Login} />
        <Route path="/forget-password/:id" component={ForgetPassword} />
        <Route path="/reset-password/:id/:token" component={ResetPassword} />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/planning" component={Calendar} />
        <PrivateRoute path="/absence" component={Absences} />
        <PrivateRoute path="/profil" component={Profil} />
        <PrivateRoute path="/missions" component={TaskList} />
        <AdminRoute path="/absence-admin" component={AbsenceAdmin} />
        <AdminRoute path="/task-admin" component={TaskAdmin} />
        <Redirect from="/" to="/home" />
      </Switch>
    </>
  );
}
export const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (getUserInfo().role === "admin") {
          return <Component {...props} />;
        }
        return (
          <>
            <Redirect from="/" to="/home" />
            <SelectProfil />
          </>
        );
      }}
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem("jwt")) {
          return <Component {...props} />;
        }
        return (
          <>
            <Redirect from="/" to="/home" />
            <SelectProfil />
          </>
        );
      }}
    />
  );
};

const rootElement = document.getElementById("app");
ReactDOM.render(
  <React.Suspense fallback={<Loader />}>
    <BrowserRouter>
      <ToastProvider>
        <App />
      </ToastProvider>
    </BrowserRouter>
  </React.Suspense>,
  rootElement
);

serviceWorker.register();
