import React from "react";
import { useLocation } from "react-router-dom";
import { Profil } from "./Profil";
import logo from "../../img/title-logo.png";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Loader } from "../../components/Loader";
import { Error } from "../../components/Error";

export const SelectProfil = () => {
  const location = useLocation();

  const { status, result, doRequest } = useAuthenticatedRequest("GET /users");
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, doRequest]);

  return (
    <>
      <div className="animation-fadeIn">
        <img
          width={150}
          style={{ marginLeft: 20, marginTop: 20 }}
          src={logo}
          alt=""
        />
        <div className="container center-container auth-container" style={{ marginTop: 100, width: "100%" }}>
          <h1 className="title text-center">Qui est-ce ?</h1>
          <div className="block">
            {!!result?.data
              ? result?.data.map((item) => <Profil key={item.id} data={item} />)
              : null}
          </div>
          {status === statuses.PENDING && <Loader />}
          {status === statuses.REJECTED && (
            <Error doRequest={doRequest} result={result} />
          )}
        </div>
      </div>
    </>
  );
};
