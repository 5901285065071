import React from "react";
import loader from "../img/loader.png";

export const Loader = ({ inContainer }) => {
  return (
    <img
      src={loader}
      className={inContainer ? "rolling-in" : "rolling"}
      alt="Chargement..."
    />
  );
};
