import React from "react";
import imgCsv from "../../img/csv file type.PNG";

export const Help = (props) => {
  return (
    <>
      <div
        className="modal"
        style={{ width: "95%", height: "90%", overflow: "auto" }}
      >
        <div onClick={props.closeModal} className="closeModal">
          <p>&times;</p>
        </div>
        <h1>Comment importer plusieurs tâches depuis un fichier excel ?</h1>
        <p>
          Veuillez dans un premier temps télécharger le fichier type pour
          l'importation :{" "}
          <a style={{ color: "#30c5f5" }} href="https://api-gestion-interne.fibeer.fr/ressources/Suivi%20des%20missions%20-%20Fichier%20d'import.csv">
            Télécharger
          </a>
        </p>
        <h4>
          <b>Remplir le fichier d'import :</b>
        </h4>
        <img width="100%" src={imgCsv} alt="" />
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "33%" }}>
            <h4>
              <b>Champs obligatoires :</b>
            </h4>
            <p style={{ color: "#30c5f5" }}>type</p>
            <p style={{ color: "#93c93c" }}>date_limite (format : aaaa-mm-jj)</p>
            <p style={{ color: "#ff8e25" }}>dossier</p>
            <p style={{ color: "#ef5a9b" }}>statut</p>
          </div>
          <div style={{ width: "33%" }}>
            <h4>
              <b>Champs optionnels : </b>
            </h4>
            <p>sous_type</p>
            <p>description</p>
            <p>personne1, personne2, personne3</p>
            <p>lien_ged</p>
            <p>commentaire</p>
            <p>quantite</p>
            <p>
              <b>Les champs du temps passé : </b>
              <br />
              day_spent, hour_spent, min_spent, sec_spent
            </p>
          </div>
          <div style={{ width: "33%" }}>
            <h4>
              <b>Champs à ne pas remplir :</b>
            </h4>
            <p>id</p>
            <p>date_debut</p>
            <p>date_fin</p>
            <p>createur</p>
            <p>date_creation</p>
          </div>
        </div>
        <p>
          Une fois le fichier d'import rempli, il suffit de le selectionner dans
          la zone et appuyer sur "importer"
        </p>
      </div>
    </>
  );
};
