import React from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import useLocalStorage from "../../components/store/use-localstorage";
import { fetcher } from "../../components/reducer/fetcher";
import axios from "axios";
import user from "../../img/user.png";
import visible from "../../img/visibility.png";
import invisible from "../../img/invisible.png";
import { Loader } from "../../components/Loader";
import { API } from "../../../package.json";
import { Iconly } from "react-iconly";
import { usePersonne } from "../..";
import { useToasts } from "../../components/store/toast-manager";

export const ProfileId = () => {
  let { id } = useParams();
  const [, setPersonne] = usePersonne();
  const location = useLocation();
  const [, setUserInfo] = useLocalStorage("user");
  const [state, dispatch] = React.useReducer(fetcher, {
    loading: false,
    error: null,
    data: {},
  });

  React.useEffect(() => {
    const getUserId = async () => {
      try {
        dispatch({ type: "LOADING" });
        axios.get(API + "users/" + id).then(({ data }) => {
          setUserInfo(data.nom);
          setPersonne(data.nom);
          dispatch({ type: "SUCCESS", payload: data });
        });
      } catch (e) {
        dispatch({ type: "ERROR", payload: e });
      }
    };
    getUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { data, loading } = state;

  return (
    <>
      <div className="profil">
        {loading ? (
          <div className="square-account-loading">
            <img src={user} alt="" />
          </div>
        ) : (
          <div className="square-account">
            <img src={data.photo} alt="" />
          </div>
        )}
        <p className="name-account">{data.nom}</p>
      </div>
    </>
  );
};

export const Login = ({ history }) => {
  let { id } = useParams();

  const { success, error: errorToast } = useToasts();

  const [password, setPassword] = React.useState("");
  const [viewPassword, setViewPassword] = React.useState(false);
  const [state, dispatch] = React.useReducer(fetcher, {
    loading: false,
    error: null,
    data: {},
  });

  const authenticate = async (id, password) => {
    try {
      dispatch({ type: "LOADING" });
      const { data } = await axios.post(API + "auth/login", {
        id,
        password,
      });

      localStorage.setItem("jwt", data.token);
      success(data.message);
      dispatch({ type: "SUCCESS", payload: data });
      history.push("/home");
    } catch (e) {
      errorToast(e.response.data.message);
      dispatch({ type: "ERROR", payload: e });
    }
  };

  const { data, error, loading } = state;

  return (
    <>
      {data && (
        <>
          <div className="modal">
            <Link className="back" to="/home">
              <Iconly name="ChevronLeft" set="light" size="large" />
            </Link>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                authenticate(id, password);
              }}
            >
              <div className="form-login">
                <ProfileId />
                <div style={{ position: "relative" }}>
                  <input
                    type={viewPassword ? "text" : "password"}
                    name="password"
                    className="input"
                    style={{ width: "100%" }}
                    minLength="1"
                    required="required"
                    placeholder="Mot de passe"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                  <img
                    className="lock"
                    onClick={() => setViewPassword((on) => !on)}
                    src={viewPassword ? invisible : visible}
                    alt=""
                  />
                  <button type="submit">Se connecter</button>
                  <Link to={`/forget-password/${id}`} className="btn-text">
                    Première connexion ou mot de passe oublié ?
                  </Link>
                </div>
              </div>
            </form>
          </div>

          {loading && <Loader />}
        </>
      )}
    </>
  );
};
