import React from "react";
import logo from "../../img/title-logo.png";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";

import regular from "../../fonts/Poppins-Regular.ttf";
import medium from "../../fonts/Poppins-Medium.ttf";
import semiBold from "../../fonts/Poppins-SemiBold.ttf";

const Pdf = ({ result }) => {
  return (
    <>
      <Document>
        <Page style={styles.body}>
          <Image style={styles.image} src={logo}></Image>
          <Text style={styles.title}>Demande d'autorisation d'absence</Text>

          <Text style={styles.subtitle}>I. Demandeur</Text>

          <Text style={styles.text}>
            Date :{" "}
            <Text style={{ fontWeight: 500 }}>
              {new Date(result?.date_creation).toLocaleDateString()}
            </Text>
          </Text>
          <Text style={styles.text}>
            Prénom :{" "}
            <Text style={{ fontWeight: 500 }}>{result?.nomDemandeur}</Text>
          </Text>

          <Text style={styles.subtitle}>II. Demande d'absence</Text>

          <Text style={styles.text}>
            Type d'absence :{" "}
            <Text style={{ fontWeight: 500 }}>{result?.type}</Text>
          </Text>
          <Text style={styles.text}>
            Date de départ (1er jour d'absence effectif) :{" "}
            <Text style={{ fontWeight: 500 }}>
              {new Date(result?.startDate).toLocaleDateString()}
            </Text>
          </Text>
          <Text style={styles.text}>
            Date de retour (1er jour de travail effectif) :{" "}
            <Text style={{ fontWeight: 500 }}>
              {new Date(result?.retourDate).toLocaleDateString()}
            </Text>
          </Text>

          <Text style={styles.subtitle}>III. Validation</Text>

          <Text style={styles.subtitle}>Demandeur :</Text>
          <Text style={styles.text}>
            Date :{" "}
            <Text style={{ fontWeight: 500 }}>
              {new Date(result?.date_creation).toLocaleDateString()}
            </Text>
          </Text>
          <Text style={styles.text}>Signature :</Text>
          <Image
            style={{ width: 80, margin: 10 }}
            src={result?.signature}
          ></Image>

          <View style={{ position: "absolute", right: 30, bottom: 92 }}>
            <Text style={styles.subtitle}>Responsable hiérarchique :</Text>
            <Text style={styles.text}>
              <Text
                style={
                  result?.status === "accept"
                    ? { color: "#93c93c", fontWeight: 500 }
                    : { color: "#ff004d", fontWeight: 500 }
                }
              >
                Absence {result?.status === "accept" ? "acceptée" : "refusée"}
              </Text>
            </Text>
            <Text style={styles.text}>
              Date :{" "}
              <Text style={{ fontWeight: 500 }}>
                {new Date(result?.dateStatus).toLocaleDateString()}
              </Text>
            </Text>
            <Text style={styles.text}>Signature : </Text>
            <Image
              style={{ width: 80, margin: 10 }}
              src={result?.signResponsable}
            ></Image>
          </View>
          <View style={styles.footer}>
            <Text>Conseil Gestion Réorganisation</Text>
            <Text>- Document Interne -</Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

Font.register({
  family: "Poppins",
  fonts: [
    { src: regular, fontWeight: 400 },
    { src: medium, fontWeight: 500 },
    { src: semiBold, fontWeight: 600 },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingHorizontal: 35,
    paddingBottom: 0,
    fontFamily: "Poppins",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 40,
    fontWeight: 600,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 16,
    margin: 15,
    fontWeight: 600,
  },
  text: {
    marginLeft: 15,
    marginTop: 7,
    marginBottom: 7,
    fontSize: 14,
    fontWeight: 400,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 150,
  },
  footer: {
    position: "fixed",
    fontSize: 8,
    textAlign: "center",
    color: "grey",
    bottom: -70,
    left: 0,
    right: 0,
  },
});

export const DownloadPDF = ({ result }) => {
  return (
    <PDFDownloadLink
      document={<Pdf result={result} />}
      fileName={`${result?.nomDemandeur} - Demande d'absence du ${new Date(
        result?.startDate
      ).toLocaleDateString()} au ${new Date(
        result?.retourDate
      ).toLocaleDateString()}.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          "Chargement du PDF"
        ) : (
          <button className="btn">Télécharger</button>
        )
      }
    </PDFDownloadLink>
  );
};
