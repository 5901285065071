import React from "react";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Loader } from "../../components/Loader";
import { Error } from "../../components/Error";
import { useLocation, Switch, Route, Link } from "react-router-dom";
import { AutoReload } from "../../components/AutoReload";
import { EventItem } from "./EventItem";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  ViewSwitcher,
  Toolbar,
  DateNavigator,
  CurrentTimeIndicator,
  MonthView,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Invited } from "./Invited";
import { usePersonne } from "../../index";
import { useToasts } from "../../components/store/toast-manager";

export const EventCalendar = () => {
  const { error } = useToasts();
  const [personne] = usePersonne();
  const location = useLocation();
  const { status, result, doRequest } = useAuthenticatedRequest(`GET /events/list/${personne}`, {
    manual: true,
  });

  React.useEffect(() => {
    if (status === statuses.REJECTED) {
      error(result ? result?.data?.message : "Pas de connexion !");
    }
    doRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === "/planning", personne]);

  const [date, setDate] = React.useState(new Date());

  const Appointment = ({ children, style, ...restProps }) => {
    const item = children[1].props.data;

    return (
      <Link to={`/planning/event/${item.id}`}>
        <Appointments.Appointment
          {...restProps}
          style={{
            ...style,
            backgroundColor: item.color,
            borderRadius: "8px",
            color: "#fff",
            paddingLeft: 7,
          }}
        >
          {children}
          <Appointments.Container style={{ padding: 0 }}>
            <p>{item.public ? "Public" : "Privé"}</p>

            <div className="invited-content-card appointments">
              <div className="round-account">
                <img src={item.photo_createur} alt="" />
              </div>
              <Invited id={item.id} />
            </div>
          </Appointments.Container>
        </Appointments.Appointment>
      </Link>
    );
  };

  return (
    <>
      {location.pathname === "/planning" && (
        <AutoReload doRequest={doRequest} />
      )}

      <Scheduler locale="fr-FR" data={result?.data}>
        <ViewState
          currentDate={date}
          defaultCurrentViewName="Month"
          onCurrentDateChange={(date) => setDate(date)}
        />
        <DayView displayName="Jour" startDayHour={8} endDayHour={21} />
        <WeekView displayName="Semaine" startDayHour={8} endDayHour={21} />
        <MonthView DayScaleCell={100} displayName="Mois" />
        <Toolbar />
        <DateNavigator />
        <ViewSwitcher />
        <Appointments appointmentComponent={Appointment} />
        <CurrentTimeIndicator updateInterval={60000} />
      </Scheduler>

      <Switch>
        <Route path="/planning/event/:id" component={EventItem} />
      </Switch>

      {status === statuses.PENDING && <Loader />}
    </>
  );
};
