import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Iconly } from "react-iconly";
import { getUserInfo } from "../../utils";

export const SideMenu = () => {
  return (
    <>
      <Link className="background-modal" to="/home" />
      <div className="sidebar right">
        <Link to="/home" className="closeModal">
          <p>&times;</p>
        </Link>

        <h1 className="title">Menu</h1>
        <Link to="/home/mailing">
          <li>
            <Iconly
              name="Message"
              primaryColor="#000"
              set="bold"
              style={{ width: 26, height: 26, marginTop: 5 }}
            />
            <p style={{ fontSize: 15 }}>Mailing</p>
          </li>
        </Link>
        {getUserInfo().role === "admin" && (
          <Link to="/home/extraction">
            <li>
              <Iconly
                name="Chart"
                primaryColor="#000"
                set="bold"
                style={{ width: 26, height: 26, marginTop: 5 }}
              />
              <p style={{ fontSize: 15 }}>Extraction</p>
            </li>
          </Link>
        )}
      </div>
    </>
  );
};
