import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Loader } from "../../components/Loader";
import { Error } from "../../components/Error";
import { useToasts } from "../../components/store/toast-manager";

export const DeleteAbsence = () => {
  let params = useParams();
  let history = useHistory();
  const { success } = useToasts();
  const { status, result, doRequest } = useAuthenticatedRequest(
    "DELETE /absences/delete/" + params.id,
    {
      manual: true,
    }
  );

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      success(result?.data.message);
      history.push("/absence");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, resolved]);

  return (
    <>
      <button className="red" onClick={() => doRequest()}>
        Supprimer la demande
      </button>

      {status === statuses.PENDING && <Loader />}
    </>
  );
};
