import React from "react";
import { CSVLink } from "react-csv";
import excel from "../../img/excel.png";

export const SidebarSearch = ({ setSidebar, result, setType, type, setDossier, dossier }) => {
  return (
    <>
      <div className="background-modal" onClick={() => setSidebar(false)} />
      <div className="sidebar">
        <div onClick={() => setSidebar(false)} className="closeModal">
          <p>&times;</p>
        </div>

        <h1 className="title">Rechercher</h1>
        <div style={{ width: "80%", margin: "auto", marginTop: 20 }}>
          <input
            style={{ width: "100%" }}
            className="input"
            placeholder="Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
          <input
            style={{ width: "100%" }}
            className="input"
            placeholder="Dossier"
            value={dossier}
            onChange={(e) => setDossier(e.target.value)}
          />
          {result?.data ? (
            <CSVLink
              data={result?.data}
              separator={";"}
              filename={`Suivi des missions - ${new Date().toLocaleDateString()}.csv`}
            >
              <img className="icon-btn-nav" src={excel} alt="" />
            </CSVLink>
          ) : null}
        </div>
      </div>
    </>
  );
};
