import React from "react";
import { useParams, useHistory } from "react-router";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { Loader } from "../../components/Loader";
import { Link } from "react-router-dom";
import { useToasts } from "../../components/store/toast-manager";

export const DetailsUser = () => {
  let history = useHistory();
  const { success } = useToasts();
  let params = useParams();
  const { status, result, doRequest } = useAuthenticatedRequest(
    "GET /users/" + params.id
  );

  const {
    status: statusDelete,
    result: resultDelete,
    doRequest: doRequestDelete,
  } = useAuthenticatedRequest("DELETE /users/delete/" + params.id, {
    manual: true,
  });

  const resolvedDelete = statusDelete === statuses.RESOLVED;

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolvedDelete) {
      success(resultDelete?.data?.message);
      history.push("/profil/manager-user");
    }
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRequest, resolvedDelete]);

  return (
    <>
      <Link to="/profil/manager-user" className="background-modal" />
      <div className="modal">
        <Link to="/profil/manager-user" className="closeModal">
          <p>&times;</p>
        </Link>
        {status === statuses.PENDING && <Loader />}
        {statusDelete === statuses.PENDING && <Loader />}
        <h1 className="title">Profil de {result?.data?.nom}</h1>
        <div className="round-account profil">
          <img src={result?.data?.photo} alt="" />
          <p>{result?.data?.nom}</p>
        </div>
        <div className="params">
          <p>
            <b>Email : </b>
            {result?.data?.email}
          </p>
          <p style={{ display: "flex" }}>
            <b>Couleur : </b>
            <span
              style={{
                backgroundColor: result?.data?.color,
                width: 10,
                height: 10,
                margin: 3,
                marginLeft: 10,
              }}
              className="circle"
            />
          </p>

          <button className="red" onClick={() => doRequestDelete()}>
            Supprimer l'utilisateur
          </button>
        </div>
      </div>
    </>
  );
};
