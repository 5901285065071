import React from "react";
import { useLocation, Switch, Route } from "react-router";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import Logout from "../../components/Logout";
import { Error } from "../../components/Error";
import { Loader } from "../../components/Loader";
import { UploadPhoto } from "./UploadPhoto";
import { Iconly } from "react-iconly";
import { Link } from "react-router-dom";
import { UpdateColor } from "./UpdateColor";
import { AdminRoute } from "../..";
import { getUserInfo } from "../../utils";
import { ManagerUser } from "./ManagerUser";

export const Profil = () => {
  let location = useLocation();
  const { status, result, doRequest } =
    useAuthenticatedRequest("GET /users/me");
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRequest, location.pathname]);

  return (
    <>
      {status === statuses.REJECTED && (
        <Error result={result} doRequest={doRequest} />
      )}
      {status === statuses.PENDING && <Loader />}
      <h1 className="title">Profil</h1>
      <div className="container">
        <div className="round-account profil">
          <Link to="/profil/update-photo">
            <div
              className="change-circle"
              style={{ position: "absolute", right: -5, bottom: -5 }}
            >
              <Iconly
                name="Edit"
                primaryColor="#000"
                set="light"
                size="medium"
              />
            </div>
          </Link>
          <img src={result?.data?.photo} alt="" />
          <p>{result?.data?.nom}</p>
        </div>
        <div className="params">
          <p>
            <b>Email : </b>
            {result?.data?.email}
          </p>
          <p style={{ display: "flex" }}>
            <b>Couleur : </b>
            <span
              style={{
                backgroundColor: result?.data?.color,
                width: 10,
                height: 10,
                margin: 3,
                marginLeft: 10,
              }}
              className="circle"
            />
            <Link
              to={{
                pathname: "/profil/update-color",
                state: {
                  couleur: result?.data?.color,
                },
              }}
            >
              <span
                className="change-circle"
                style={{ marginTop: -10, marginLeft: 10 }}
              >
                <Iconly
                  name="Edit"
                  primaryColor="#000"
                  set="light"
                  size="medium"
                />
              </span>
            </Link>
          </p>
          {getUserInfo().role === "admin" && (
            <Link className="btn" to="/profil/manager-user">
              Gérer les utilisateurs
            </Link>
          )}

          <button className="red" onClick={Logout}>
            Se déconnecter
          </button>
        </div>
      </div>
      <Switch>
        <AdminRoute path="/profil/manager-user" component={ManagerUser} />
        <Route path="/profil/update-color" component={UpdateColor} />
        <Route path="/profil/update-photo" component={UploadPhoto} />
      </Switch>
    </>
  );
};
