import React from "react";
import logout from "./Logout";

export const Error = (props) => {
  const { result, doRequest } = props;
  return (
    <>
      {(result?.message).includes("400") && (
        <>
          <div className="background-modal" />
          <div className="modal error">
            <div>
              <p>Session expirée !</p>
              <button onClick={logout}>Se reconnecter</button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
