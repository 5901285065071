import React from "react";
import { useToasts } from "../../components/store/toast-manager";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";

export const ChangeStatus = ({ id, statut }) => {
  const { success } = useToasts();
  const { status, doRequest } = useAuthenticatedRequest(
    "PUT /tasks/encours/" + id,
    {
      manual: true,
    }
  );

  const { status: statusOk, doRequest: doRequestOk } = useAuthenticatedRequest(
    "PUT /tasks/terminer/" + id,
    {
      manual: true,
    }
  );

  const resolved = status === statuses.RESOLVED;
  const resolvedOk = statusOk === statuses.RESOLVED;

  React.useEffect(() => {
    if (resolvedOk) {
      success("Mission terminée !");
    }
    if (resolved) {
      success("Mission commencée !");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolved, resolvedOk]);

  return (
    <>
      {statut === "atraiter" && (
        <li onClick={() => doRequest()}>Commencer la mission</li>
      )}
      {statut === "encours" && (
        <li onClick={() => doRequestOk()}>Terminer la mission</li>
      )}
    </>
  );
};
