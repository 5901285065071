import React from "react";
import axios from "axios";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { API } from "../../../package.json";
import { Link, useParams, useLocation } from "react-router-dom";
import { fetcher } from "../../components/reducer/fetcher";
import { Loader } from "../../components/Loader";
import clock from "../../img/clock.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { Error } from "../../components/Error";
import { DelTask } from "./DelTask";
import { useToasts } from "../../components/store/toast-manager";
import { getUserInfo } from "../../utils";
registerLocale("fr", fr);

const Form = ({ initialValues, onSubmit, error }) => {
  const [lienGed, setlien_ged] = React.useState(initialValues.lien_ged);
  const [dossierName, setDossier] = React.useState(initialValues.dossier);
  const [descriptionName, setDescription] = React.useState(
    initialValues.description
  );
  const [statutName, setStatut] = React.useState(initialValues.statut);
  const [typeName, setType] = React.useState(initialValues.type);
  const [sous_typeName, setsous_type] = React.useState(initialValues.sous_type);
  const [dateLimite, setDate_limite] = React.useState(
    initialValues.date_limite
  );
  const [quantite, setQuantite] = React.useState(initialValues.quantite);

  const [createur] = React.useState(initialValues.createur);
  const [date_creation] = React.useState(initialValues.date_creation);

  const [date_debut, setDate_debut] = React.useState(initialValues.date_debut);
  const [date_fin, setDate_fin] = React.useState(initialValues.date_fin);

  const date_end = new Date(date_fin);
  const date_start = new Date(date_debut);

  let diff = new Date(date_end.getTime() - date_start.getTime());

  const day_interval = diff.getDate();
  const hour_interval = diff.getHours();
  const min_interval = diff.getMinutes();
  const sec_interval = diff.getSeconds();

  const [day_spent, setDay_spent] = React.useState(initialValues.day_spent);
  const [hour_spent, setHour_spent] = React.useState(initialValues.hour_spent);
  const [min_spent, setMin_spent] = React.useState(initialValues.min_spent);
  const [sec_spent, setSec_spent] = React.useState(initialValues.sec_spent);

  function parseDate(dateStr) {
    const matchFrenchDate = dateStr.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (!matchFrenchDate) throw new SyntaxError("Invalid Date");
    const [, day, month, year] = matchFrenchDate;
    return new Date([year, month, day].join("-"));
  }

  const { status, result, doRequest } = useAuthenticatedRequest("GET /users");
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRequest]);

  const submit = () => {
    onSubmit({
      lienGed,
      dossierName,
      descriptionName,
      statutName,
      typeName,
      sous_typeName,
      dateLimite,
      quantite,
      date_debut,
      date_fin,
      day_spent,
      hour_spent,
      min_spent,
      sec_spent,
    });
  };

  return (
    <>
      <h3>
        {typeName} : {dossierName}
      </h3>
      <p>
        créée par <b>{createur}</b> le{" "}
        <b>{new Date(date_creation).toLocaleDateString()}</b> à{" "}
        <b>
          {new Date(date_creation).getHours() < 10
            ? "0" + new Date(date_creation).getHours()
            : new Date(date_creation).getHours()}{" "}
          :{" "}
          {new Date(date_creation).getMinutes() < 10
            ? "0" + new Date(date_creation).getMinutes()
            : new Date(date_creation).getMinutes()}
        </b>
      </p>

      <p>Lien GED :</p>
      <input
        className="input"
        type="text"
        onChange={(e) => setlien_ged(e.target.value)}
        value={lienGed}
      />

      <p>Dossier :</p>
      <input
        className="input"
        type="text"
        onChange={(e) => setDossier(e.target.value)}
        value={dossierName}
      />

      <p>Description :</p>
      <textarea
        className="input"
        type="text"
        onChange={(e) => setDescription(e.target.value)}
        value={descriptionName}
      />

      <div>
        <p>Date limite :</p>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={parseDate(new Date(dateLimite).toLocaleDateString())}
          onChange={(date) => setDate_limite(date)}
          locale="fr"
          className="input input-datePicker dateTask"
          disabled={statutName === "ok"}
        />
      </div>
      <div>
        <p>Type :</p>
        <div className="select">
          <select onChange={(e) => setType(e.target.value)}>
            <option value={typeName}>{typeName}</option>
            <option value="Arret de Travail">Arret de travail</option>
            <option value="Solde de tout compte">Solde de tout compte</option>
            <option value="Embauche">Embauche</option>
            <option value="Pass">Pass</option>
            <option value="Portail">Portail</option>
            <option value="Externalisation et SOS Paie">
              Externalisation et SOS Paie
            </option>
            <option value="Sauvegarde">Sauvegarde</option>
            <option value="Autres">Autres</option>
          </select>
        </div>
      </div>
      {typeName && (
        <div>
          <p>Sous-type :</p>
          <div className="select">
            {typeName === "Arret de Travail" && (
              <select onChange={(e) => setsous_type(e.target.value)}>
                <option value={sous_typeName}>{sous_typeName}</option>
                <option value="DSN">DSN</option>
                <option value="Depot">Depot</option>
              </select>
            )}
            {typeName === "Solde de tout compte" && (
              <select onChange={(e) => setsous_type(e.target.value)}>
                <option value={sous_typeName}>{sous_typeName}</option>
                <option value="Bulletin STC">Bulletin STC</option>
                <option value="Depot">Depot</option>
              </select>
            )}
            {typeName === "Embauche" && (
              <select onChange={(e) => setsous_type(e.target.value)}>
                <option value={sous_typeName}>{sous_typeName}</option>
                <option value="Contrat">Contrat</option>
                <option value="DPAE">DPAE</option>
                <option value="Embauche Pégase">Embauche Pégase</option>
                <option value="Autre">Autre</option>
              </select>
            )}
            {typeName === "Portail" && (
              <select onChange={(e) => setsous_type(e.target.value)}>
                <option value={sous_typeName}>{sous_typeName}</option>
                <option value="Contrat">Assistance ++</option>
              </select>
            )}
            {typeName === "Externalisation et SOS Paie" && (
              <select onChange={(e) => setsous_type(e.target.value)}>
                <option value={sous_typeName}>{sous_typeName}</option>
                <option value="Préparation du fichier navette">
                  Préparation du fichier navette
                </option>
                <option value="Réalisation des Bulletins de salaire">
                  Réalisation des Bulletins de salaire
                </option>
                <option value="Bulletin rectificatif">
                  Bulletin rectificatif
                </option>
                <option value="Solde de tout compte">
                  Solde de tout compte
                </option>
                <option value="Décomposition des heures">
                  Décomposition des heures
                </option>
                <option value="Suivi des RC et/ou RCR">
                  Suivi des RC et/ou RCR
                </option>
                <option value="Etat des CP au basculement et fractionnement">
                  Etat des CP au basculement et fractionnement
                </option>
                <option value="Etat des effectifs">Etat des effectifs</option>
                <option value="Fichier de virement">Fichier de virement</option>
                <option value="DSN (Mensuelle)">DSN (mensuelle)</option>
                <option value="Déclaration DSN mensuelle">
                  Déclaration DSN mensuelle
                </option>
                <option value="Dépot DSN mensuelle">Dépot DSN mensuelle</option>
                <option value="Sauvegarde mensuelle">
                  Sauvegarde mensuelle
                </option>
                <option value="Déclaration des Accidents du Travail">
                  Déclaration des Accidents du Travail
                </option>
                <option value="Suivi des IJSS">Suivi des IJSS</option>
                <option value="Dossier prise en charge maladie par prévoyance">
                  Dossier prise en charge maladie par prévoyance
                </option>
                <option value="Suivi des saisies-arrêt">
                  Suivi des saisies-arrêt
                </option>
                <option value="Calcul garantie annuelle de rémunération">
                  Calcul garantie annuelle de rémunération
                </option>
                <option value="Autres">Autres</option>
              </select>
            )}
            {typeName === "Sauvegarde" && (
              <select onChange={(e) => setsous_type(e.target.value)}>
                <option value={sous_typeName}>{sous_typeName}</option>
                <option value="Bulletin de paie (PDF)">
                  Bulletin de paie (PDF)
                </option>
                <option
                  value="Bulletin en version non clarifiée (détail des
                        cotisations - tarif à l'envoi global)"
                >
                  Bulletin en version non clarifiée (détail des cotisations -
                  tarif à l'envoi global)
                </option>
                <option value="Transmission des étas des règlements (PDF)">
                  Transmission des étas des règlements (PDF)
                </option>
                <option value="Journal des salaires (PDF)">
                  Journal des salaires (PDF)
                </option>
                <option value="Tableaux des charges par caisse mensuels et trimestriels (PDF)">
                  Tableaux des charges par caisse mensuels et trimestriels (PDF)
                </option>
                <option value="Fichiers DSN mensuelle + justificatif (PDF)">
                  Fichiers DSN mensuelle + justificatif (PDF)
                </option>
                <option value="Etat des paiements organismes (PDF)">
                  Etat des paiements organismes (PDF)
                </option>
                <option value="Journal des coûts salariaux (PDF)">
                  Journal des coûts salariaux (PDF)
                </option>
                <option value="Journal des coûts salariaux (Excel)">
                  Journal des coûts salariaux (Excel)
                </option>
                <option value="Journal des OD de paie (PDF)">
                  Journal des OD de paie (PDF)
                </option>
                <option
                  value="Fourniture du fichier d'OD de paie transférable en
                        comptabilité"
                >
                  Fourniture du fichier d'OD de paie transférable en
                  comptabilité
                </option>
                <option value="Extractions mensuelles sous format Excel à la demande">
                  Extractions mensuelles sous format Excel à la demande
                </option>
                <option value="Provision des congés payés à la date de basculement">
                  Provision des congés payés à la date de basculement
                </option>
                <option value="Registre d'entrée et sortie du personnel (PDF)">
                  Registre d'entrée et sortie du personnel (PDF)
                </option>
                <option
                  value="Etat des retenues sur salaire (saisie, pension
                        alimentaire, prêt, opposition sur salaire)"
                >
                  Etat des retenues sur salaire (saisie, pension alimentaire,
                  prêt, opposition sur salaire)
                </option>
                <option value="Etat des trop perçu en PDF">
                  Etat des trop perçu en PDF
                </option>
                <option value="Etat complémentaire pour bilan comptable">
                  Etat complémentaire pour bilan comptable
                </option>
              </select>
            )}
          </div>
        </div>
      )}
      <div>
        <p>Quantité :</p>
        <input
          className="input"
          type="number"
          onChange={(e) => setQuantite(e.target.value)}
          value={quantite}
        />
      </div>

      {statutName === "ok" && (
        <>
          <div style={{ display: "flex", marginTop: 50 }}>
            <img width={30} height={30} src={clock} alt="" />
            <p style={{ margin: 4, marginLeft: 10 }}>
              Temps passé :
              {statutName === "ok" &&
              !day_spent &&
              !hour_spent &&
              !min_spent &&
              !sec_spent ? (
                <>
                  {" "}
                  {day_interval - 1} Jours / {hour_interval - 1} Heures /{" "}
                  {min_interval} Minutes / {sec_interval} Secondes
                </>
              ) : (
                <>
                  {" "}
                  {day_spent ? day_spent : 0} Jours /{" "}
                  {hour_spent ? hour_spent : 0} Heures /{" "}
                  {min_spent ? min_spent : 0} Minutes /{" "}
                  {sec_spent ? sec_spent : 0} Secondes{" "}
                  {day_spent || hour_spent || min_spent || sec_spent
                    ? "(Modifié)"
                    : ""}
                </>
              )}
            </p>
          </div>
        </>
      )}

      {statutName === "encours" && (
        <>
          <p>
            Tâche commencée le {date_start.getDate()} /{" "}
            {date_start.getMonth() + 1} / {date_start.getFullYear()} à{" "}
            {date_start.getHours()}h : {date_start.getMinutes()}
          </p>
        </>
      )}
      {statutName === "ok" && (
        <>
          <p>
            Tâche commencée le {date_start.getDate()} /{" "}
            {date_start.getMonth() + 1} / {date_start.getFullYear()} à{" "}
            {date_start.getHours()}h : {date_start.getMinutes()}
            <br />
            Tache terminée le {date_end.getDate()} / {date_end.getMonth() + 1} /{" "}
            {date_end.getFullYear()} à {date_end.getHours()}h :{" "}
            {date_end.getMinutes()}
          </p>
          {statutName === "ok" && (
            <>
              <p>Pour modifier le temps passé :</p>
              <div className="time-modified">
                <p>Jours</p>
                <input
                  type="number"
                  className="input"
                  value={day_spent}
                  onChange={(e) => setDay_spent(e.target.value)}
                  min="0"
                  max="31"
                />
                <p> Heures</p>
                <input
                  type="number"
                  className="input"
                  value={hour_spent}
                  onChange={(e) => setHour_spent(e.target.value)}
                  min="0"
                  max="23"
                />
                <p>Minutes</p>
                <input
                  type="number"
                  className="input"
                  value={min_spent}
                  onChange={(e) => setMin_spent(e.target.value)}
                  min="0"
                  max="59"
                />
                <p>Secondes</p>
                <input
                  type="number"
                  className="input"
                  value={sec_spent}
                  onChange={(e) => setSec_spent(e.target.value)}
                  min="0"
                  max="59"
                />
              </div>
            </>
          )}
        </>
      )}

      <button
        className={
          dossierName && statutName && typeName && dateLimite
            ? statutName + " btn-updateTask "
            : "disabled"
        }
        onClick={submit}
      >
        Modifier
      </button>

      {error && <Error />}
    </>
  );
};

export const UpdateTask = ({ history }) => {
  const { success } = useToasts();
  let { id } = useParams();
  const location = useLocation();

  const [state, dispatch] = React.useReducer(fetcher, {
    loading: false,
    error: null,
    data: null,
  });

  React.useEffect(() => {
    const getTaskId = async () => {
      try {
        dispatch({ type: "LOADING" });
        axios
          .get(API + "tasks/" + id, {
            headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
          })
          .then(({ data }) => {
            dispatch({ type: "SUCCESS", payload: data });
          });
      } catch (e) {
        dispatch({ type: "ERROR", payload: e });
      }
    };
    getTaskId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const updateTask = async ({
    lienGed,
    dossierName,
    descriptionName,
    statutName,
    typeName,
    sous_typeName,
    personneName1,
    personneName2,
    personneName3,
    dateLimite,

    quantite,
    date_debut,
    date_fin,
    day_spent,
    hour_spent,
    min_spent,
    sec_spent,
  }) => {
    try {
      dispatch({ type: "LOADING" });
      const { data } = await axios.put(
        API + "tasks/update/" + id,
        {
          lienGed,
          dossierName,
          descriptionName,
          statutName,
          typeName,
          sous_typeName,
          personneName1,
          personneName2,
          personneName3,
          dateLimite,

          quantite,
          date_debut,
          date_fin,
          day_spent,
          hour_spent,
          min_spent,
          sec_spent,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
        }
      );
      success("Mission modifiée !");
      history.push(
        getUserInfo().role === "admin" ? "/task-admin" : "/missions"
      );
      dispatch({ type: "SUCCESS", payload: data });
    } catch (e) {
      dispatch({ type: "ERROR", payload: e });
    }
  };
  const { loading, error, data } = state;
  return (
    <>
      <Link
        to={getUserInfo().role === "admin" ? "/task-admin" : "/missions"}
        className="background-modal"
      />
      <div className="modal">
        <Link
          to={getUserInfo().role === "admin" ? "/task-admin" : "/missions"}
          className="closeModal"
        >
          <p>&times;</p>
        </Link>
        <div className="container-modal">
          {loading && (
            <>
              <div className="modal-load_background" />
              <Loader />
            </>
          )}
          {!data && !loading && <h1>Cette tâche n'existe pas !</h1>}
          {data && !loading && (
            <>
              <Form onSubmit={updateTask} initialValues={data} error={error} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
