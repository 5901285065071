import React from "react";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { useHistory } from "react-router-dom";
import { useToasts } from "../../components/store/toast-manager";

export const DeleteEvent = ({ id }) => {
  let history = useHistory();
  const { success } = useToasts();

  const { status, result, doRequest } = useAuthenticatedRequest(
    "DELETE /events/delete/" + id,
    {
      manual: true,
    }
  );

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      success(result?.data.message);
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, resolved]);

  return (
    <>
      <button onClick={() => doRequest()} className="red">
        Supprimer
      </button>
    </>
  );
};
