import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import { Loader } from "../../components/Loader";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { Iconly } from "react-iconly";
import { isMobileOnly } from "react-device-detect";

export const Formation = () => {
  let location = useLocation();
  const { status, result, doRequest } = useAuthenticatedRequest(
    "GET /events/soon/formation"
  );
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, doRequest]);

  return (
    <>
      {status === statuses.PENDING && <Loader />}

      <div style={{ display: "flex" }}>
        <h2>Les formations à venir</h2>
        <div className="circle">
          <p>{result?.data?.length}</p>
        </div>
      </div>

      <Swiper spaceBetween={5} slidesPerView={isMobileOnly ? 1.8 : 9.5}>
        {result?.data?.map((item) => (
          <SwiperSlide key={item.id}>
            <Link
              to={{
                pathname: "/home/event/" + item.id,
                state: {
                  id: item.id,
                  title: item.title,
                  type: item.type,
                  createur: item.createur,
                  date_creation: item.date_creation,
                  startDate: item.startDate,
                  endDate: item.endDate,
                },
              }}
            >
              <div
                className={
                  new Date(item.startDate) < new Date() &&
                  new Date(item.endDate) > new Date()
                    ? "animated"
                    : null
                }
                style={{
                  backgroundImage: `linear-gradient(
                      to right,
                      #fff0 20%,
                      #fff0 40%,
                      ${item.color} 10%,
                      ${item.color}  10%,
                      #fff0 70%,
                      #fff0 100%
                    )`,
                }}
              />
              <div
                className="card formation"
                style={{ backgroundColor: item.color }}
              >
                <div className="card-content">
                  <h1>{item.title}</h1>
                  <div className="card-date">
                    <Iconly name="Calendar" primaryColor="#fff" set="light" />
                    <p>
                      {new Date(item.startDate).toLocaleDateString()} à{" "}
                      {new Date(item.startDate).getHours()}h
                      {new Date(item.startDate).getMinutes()}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
        {result?.data && result?.data.length === 0 && (
          <p className="nothing">Aucune formation !</p>
        )}
      </Swiper>
    </>
  );
};
