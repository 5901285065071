import React from "react";

export const Success = (props) => {
  return (
    <>
      <div className="background-modal" />
      <div className="toast success">
        <p>{props.text} avec succès !</p>
      </div>
    </>
  );
};
