import useInterval from "./store/use-interval";

export const AutoReload = (props) => {
  const { doRequest } = props;
  useInterval(() => {
    doRequest();
  }, 60000);

  return null;
};
