import jwt_decode from "jwt-decode";

export const getUserInfo = () => {
  if (localStorage.getItem("jwt")) {
    var decoded = jwt_decode(localStorage.getItem("jwt"));
    return decoded;
  } else {
    return false;
  }
};
