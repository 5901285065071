import React from "react";
import { statuses } from "../../components/store/use-axios";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { Task } from "./Task";
import { AddTask } from "./AddTask";
import { Link, useLocation, Switch, Route } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { UpdateTask } from "./UpdateTask";
import { AutoReload } from "../../components/AutoReload";
import { ImportTask } from "./ImportTask";
import { Iconly } from "react-iconly";
import { SidebarSearch } from "./SidebarSearch";
import { useToasts } from "../../components/store/toast-manager";
import { getUserInfo } from "../../utils";

export const TaskList = () => {
  const [sidebar, setSidebar] = React.useState(false);
  const [dossier, setDossier] = React.useState("");
  const [type, setType] = React.useState("");
  const location = useLocation();
  const { success } = useToasts();
  const { status, result, doRequest } = useAuthenticatedRequest("GET /tasks");
  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, doRequest, success]);

  return (
    <>
      {location.pathname === "/missions" && (
        <AutoReload doRequest={doRequest} />
      )}
      <h1 className="title">Mes Missions</h1>
      <div className="toolbar">
        <Link
          className="btn-end"
          to={
            getUserInfo().role === "admin"
              ? "/task-admin/addtask"
              : "/missions/addtask"
          }
        >
          <Iconly name="Plus" primaryColor="#30c4f5" set="bold" />
        </Link>
      </div>
      <div className="desktop-toolbar">
        <input
          className="btn-end input"
          onChange={(e) => setDossier(e.target.value)}
          value={dossier}
          placeholder="Rechercher par dossier.."
        />
        <Link
          className="btn-end-bottom"
          to={
            getUserInfo().role === "admin"
              ? "/task-admin/addtask"
              : "/missions/addtask"
          }
        >
          <p>Ajouter une mission</p>
          <span
            style={{
              fontSize: 24,
              fontWeight: 300,
              marginTop: 10,
              marginLeft: 5,
            }}
          >
            +
          </span>
        </Link>
      </div>

      <div className="card-container">
        <div className="card-block">
          <h2 className="before-blue">à traiter</h2>
          {result?.data
            ?.filter((item) => item.statut.includes("atraiter"))
            .filter((item) => item.dossier.toLowerCase().includes(dossier))
            .map((item) => (
              <Task key={item.id} data={item} />
            ))}
        </div>

        <div className="card-block">
          <h2 className="before-orange">En cours</h2>
          {result?.data
            ?.filter((item) => item.statut.includes("encours"))
            .filter((item) => item.dossier.toLowerCase().includes(dossier))
            .map((item) => (
              <Task key={item.id} data={item} />
            ))}
        </div>

        <div className="card-block">
          <h2 className="before-green">Terminée</h2>
          {result?.data
            ?.filter((item) => item.statut.includes("ok"))
            .filter((item) => item.dossier.toLowerCase().includes(dossier))
            .map((item) => (
              <Task key={item.id} data={item} />
            ))}
        </div>
      </div>
      {status === statuses.PENDING && <Loader />}

      {sidebar && (
        <SidebarSearch
          dossier={dossier}
          type={type}
          setDossier={setDossier}
          setType={setType}
          result={result}
          setSidebar={setSidebar}
        />
      )}

      <Switch>
        <Route path="/missions/addtask" component={AddTask} />
        <Route path="/missions/import-task" component={ImportTask} />
        <Route path="/missions/updatetask/:id" component={UpdateTask} />
      </Switch>
    </>
  );
};
