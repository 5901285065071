import React from "react";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";

export const Invited = ({ id }) => {
  const { status, result, doRequest } = useAuthenticatedRequest("GET /events/invited/" + id);

  const resolved = status === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved) {
      doRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {result?.data?.map((item) => (
        <div key={item.id} className="round-account">
          <img src={item.photo} alt="" />
        </div>
      ))}
    </>
  );
};
