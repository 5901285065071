import React from "react";
import { Iconly } from "react-iconly";
import { Link, useLocation, useParams } from "react-router-dom";
import { DeleteEvent } from "./DeleteEvent";
import { Loader } from "../../components/Loader";
import { useAuthenticatedRequest } from "../../components/store/use-api";
import { statuses } from "../../components/store/use-axios";
import { AddInvite } from "./AddInvite";
import { DeleteInvite } from "./DeleteInvite";

export const EventItem = ({ history }) => {
  const [success, setSuccess] = React.useState(false);
  const location = useLocation();
  const params = useParams();
  const [modalInvite, setModalInvite] = React.useState(false);
  const { status, result, doRequest } = useAuthenticatedRequest(
    "GET /events/" + params.id
  );

  const {
    status: statusInvited,
    result: resultInvited,
    doRequest: doRequestInvited,
  } = useAuthenticatedRequest("GET /events/invited/" + params.id);

  const { result: resultUser, doRequest: doRequestUser } =
    useAuthenticatedRequest("GET /users/me");

  const resolved = status === statuses.RESOLVED;
  const resolvedInvited = statusInvited === statuses.RESOLVED;
  React.useEffect(() => {
    if (resolved && resolvedInvited) {
      doRequest();
      doRequestInvited();
      doRequestUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, doRequest, setModalInvite, modalInvite, success]);

  const {
    id,
    title,
    createur,
    date_creation,
    startDate,
    endDate,
    photo_createur,
    type,
  } = result?.data ? result?.data : "";

  return (
    <>
      <>
        <div onClick={() => history.goBack()} className="background-modal" />

        <div className="modal">
          <Link
            to={{
              pathname: "/planning/addevent",
              state: {
                titleCopy: title,
                startDateCopy: startDate,
                endDateCopy: endDate,
                typeCopy: type,
                publicCopy: !result?.data?.public,
              },
            }}
            className="btn-duplicate"
          >
            <Iconly name="Swap" primaryColor="#000" set="light" size="large" />
          </Link>
          <button onClick={() => history.goBack()} className="closeModal">
            <p>&times;</p>
          </button>
          {status === statuses.PENDING ? (
            <Loader />
          ) : (
            <>
              <div className="container-modal">
                {result?.data === "" ? (
                  <>
                    <h3>Évènement introuvable !</h3>
                    <Link className="btn" to="/home">
                      Retourner à la page d'accueil
                    </Link>
                  </>
                ) : (
                  <>
                    <h3>{title}</h3>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{ marginLeft: 0, height: 30, width: 30 }}
                        className="round-account"
                      >
                        <img src={photo_createur} alt="" />
                      </div>
                      <p style={{ color: "#4A4A4A", fontSize: 12 }}>
                        Créé par <b>{createur}</b> le{" "}
                        <b>{new Date(date_creation).toLocaleDateString()}</b>
                      </p>
                    </div>
                    {new Date(startDate).toLocaleDateString() ===
                    new Date(endDate).toLocaleDateString() ? (
                      <p>
                        {type} le{" "}
                        <b>{new Date(startDate).toLocaleDateString()}</b> de{" "}
                        <b>
                          {new Date(startDate).getHours()}h
                          {new Date(startDate).getMinutes()}
                        </b>{" "}
                        à{" "}
                        <b>
                          {new Date(endDate).getHours()}h
                          {new Date(endDate).getMinutes()}
                        </b>
                      </p>
                    ) : (
                      <p>
                        {type} du{" "}
                        <b>{new Date(startDate).toLocaleDateString()}</b> au{" "}
                        <b>{new Date(endDate).toLocaleDateString()}</b>
                      </p>
                    )}
                    <div style={{ marginTop: 30 }}>
                      <b>Invités</b>
                      {resultInvited?.data?.map((item) => (
                        <div
                          style={{ display: "flex", marginLeft: -10 }}
                          key={item.id}
                        >
                          <div className="round-account">
                            <img src={item.photo} alt="" />
                          </div>
                          <p>
                            {item.nom === "" ? (
                              item.email
                            ) : item.nom === resultUser?.data?.nom ? (
                              <b>Moi</b>
                            ) : (
                              item.nom
                            )}
                          </p>

                          <Iconly
                            style={{ marginTop: 13, marginLeft: 5 }}
                            name={
                              item.status === "pending"
                                ? "MoreSquare"
                                : item.status === "accept"
                                ? "TickSquare"
                                : "CloseSquare"
                            }
                            primaryColor={
                              item.status === "pending"
                                ? "#ff8e25"
                                : item.status === "accept"
                                ? "#93c93c"
                                : "#ff004d"
                            }
                            set="light"
                            size="medium"
                          />
                          {createur === resultUser?.data.nom && (
                            <DeleteInvite
                              setSuccess={setSuccess}
                              id={item.id}
                            />
                          )}
                        </div>
                      ))}
                      {resultInvited?.data?.length === 0 && <p>Aucun invité</p>}
                    </div>
                    {createur === resultUser?.data.nom && (
                      <>
                        <button onClick={() => setModalInvite(true)}>
                          Ajouter un invité
                        </button>
                        <DeleteEvent id={id} />
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>

        {modalInvite && (
          <AddInvite
            modalInvite={modalInvite}
            setModalInvite={setModalInvite}
            title={title}
            type={type}
            startDate={startDate}
            endDate={endDate}
            id={id}
          />
        )}
      </>
    </>
  );
};
