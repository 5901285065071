import React from "react";
import { Link } from "react-router-dom";

export const Profil = (props) => {
  return (
    <>
      <Link to={"/login/" + props.data.id} className="block-item">
        <div className="square-account">
          <img src={props.data.photo} alt="" />
        </div>
        <p className="name-account">{props.data.nom}</p>
      </Link>
    </>
  );
};
