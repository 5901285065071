import React from "react";
import { Link } from "react-router-dom";

export const Mailing = () => {
  return (
    <>
      <Link to="/home" className="background-modal" />
      <div className="modal sheet" style={{ height: 450 }}>
        <Link to="/home" className="closeModal">
          <p>&times;</p>
        </Link>
        <h1 className="title">Mailing</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: 50,
            flexWrap: "wrap",
          }}
        >
          <Link to="/home/mailing/linkedin" className="btn">Partager Post LinkedIn</Link>
        </div>
      </div>
    </>
  );
};
