import React from "react";

function ToggleSwitch({ toggle, setToggle, text }) {
  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={toggle}
        onChange={() => setToggle((on) => !on)}
      />
      <span className="switch" />
      <p style={{ width: 100 }}>{text}</p>
    </label>
  );
}
export default ToggleSwitch;
